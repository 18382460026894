import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './PaymentResult.css';
import api from '../utils/api';

const PaymentResult = ({ setUserId }) => {
  const [paymentResult, setPaymentResult] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const result = searchParams.get('result');
    const scheduleId = searchParams.get('scheduleId');
    
    // 결제 정보 관련 파라미터들 (URL 인코딩된 값은 디코딩하여 사용)
    const orderId = searchParams.get('orderId');
    const orderNo = searchParams.get('orderNo');
    const orderDate = searchParams.get('orderDate');
    const amount = searchParams.get('amount') || '';
    const productName = searchParams.get('productName') ? decodeURIComponent(searchParams.get('productName')) : '';
    const tid = searchParams.get('tid') || '';
    
    // 디버깅을 위해 URL 파라미터 출력
    console.log('URL 파라미터:', { result, scheduleId, orderId, orderNo, orderDate, amount, productName, tid });
    console.log('전체 URL:', location.search);

    if (result === 'success') {
      setPaymentResult({ 
        success: true, 
        message: '결제가 성공적으로 완료되었습니다.' 
      });

      // 주문번호 결정 (orderDate > orderNo > orderId > 생성된 번호)
      // orderDate를 주문번호 형식으로 변환
      const orderDateFormatted = orderDate ? formatOrderDate(orderDate) : null;
      const finalOrderId = orderDateFormatted || orderNo || orderId || generateOrderNumber();
      console.log('최종 주문번호:', finalOrderId);

      // scheduleId가 있으면 스포츠 코스 결제
      if (scheduleId) {
        fetchSportsCourseDetails(scheduleId, finalOrderId, amount, productName);
      } else {
        // 클래스 상품 결제 - URL 파라미터 바로 사용
        setPaymentDetails({
          type: 'class',
          orderNo: finalOrderId,
          productName: productName || '[상품명 정보 없음]',
          amount: formatAmount(amount)
        });
        setIsLoading(false);
      }
    } else if (result === 'fail') {
      setPaymentResult({ success: false, message: '결제에 실패했습니다.' });
      setIsLoading(false);
    } else {
      setPaymentResult({ success: false, message: '알 수 없는 결제 결과입니다.' });
      setIsLoading(false);
    }

    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, [location, setUserId]);

  // orderDate를 주문번호 형식으로 변환하는 함수
  const formatOrderDate = (dateStr) => {
    if (!dateStr) return null;
    // YYYYMMDDHHmmss 형식이면 그대로 반환
    if (dateStr.length === 14) {
      return dateStr;
    }
    // 다른 형식(예: YYYY-MM-DD HH:mm:ss)이면 변환
    try {
      const date = new Date(dateStr);
      if (!isNaN(date.getTime())) {
        return date.toISOString().replace(/[-:.TZ]/g, '').substring(0, 14);
      }
    } catch (e) {
      console.error('주문일자 변환 오류:', e);
    }
    return dateStr; // 변환 실패 시 원본 반환
  };

  // 금액 포맷팅 함수
  const formatAmount = (amount) => {
    if (!amount) return '금액 정보 없음';
    try {
      return `${parseInt(amount).toLocaleString()}원`;
    } catch (e) {
      console.error('금액 포맷팅 오류:', e);
      return `${amount}원`;
    }
  };

  const generateOrderNumber = () => {
    const orderNum = new Date().toISOString().replace(/[-:.TZ]/g, '').substring(0, 14);
    console.log('생성된 주문번호:', orderNum);
    return orderNum;
  };

  const fetchSportsCourseDetails = async (scheduleId, orderId, amountParam, productNameParam) => {
    try {
      console.log('스포츠 코스 상세정보 요청:', { scheduleId, orderId });
      const response = await api.get(`${API_BASE_PATH}/get-sports-schedule/${scheduleId}/`);
      const data = response.data;
      
      console.log('스포츠 코스 데이터:', data);
      
      // 금액은 URL 파라미터 또는 API 응답 데이터에서 가져옴
      const amount = amountParam 
        ? formatAmount(amountParam) 
        : data.base_price 
          ? formatAmount(data.base_price) 
          : '금액 정보 없음';
      
      // 상품명은 URL 파라미터 또는 API 데이터에서 가져옴
      const sportName = data.sport || '스포츠';
      const courseName = data.course || '과정';
      const productName = productNameParam || `[${sportName}] ${courseName}`;
      
      console.log('설정할 결제 상세정보:', { orderId, productName, amount });
      
      setPaymentDetails({
        type: 'sports',
        orderNo: orderId,
        productName: productName,
        sport: sportName,
        course: courseName,
        location: data.location || '장소 정보 없음',
        date: data.date || '일정 정보 없음',
        time: data.startTime && data.endTime ? `${data.startTime} - ${data.endTime}` : '시간 정보 없음',
        amount: amount
      });
    } catch (error) {
      console.error('스포츠 코스 정보 가져오기 실패:', error);
      
      // API 호출 실패 시 기본 정보로 대체
      setPaymentDetails({
        type: 'sports',
        orderNo: orderId,
        productName: productNameParam || '[상품명 정보 없음]',
        sport: '스포츠',
        course: '과정',
        location: '장소 정보',
        date: '일정 정보',
        time: '시간 정보',
        amount: formatAmount(amountParam)
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleDetailsClick = () => {
    navigate('/sports-learning');
  };

  if (!paymentResult || isLoading) {
    return (
      <div className="payment-result-container">
        <div className="result-card">
          <h2 className="result-title">결제 결과를 확인 중입니다...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="payment-result-container">
      <div className="result-card">
        <h2 className="payment-header">결제완료</h2>

        <div className={`result-message-box ${paymentResult.success ? 'success' : 'failure'}`}>
          <h3>결제{paymentResult.success ? '가 완료되었습니다.' : '에 실패했습니다.'}</h3>
          <p>결제내역/정보를 확인하시기 바랍니다.</p>
        </div>

        {paymentResult.success && paymentDetails && (
          <div className="payment-details">
            <div className="payment-info-item">
              <span className="label">주문번호</span>
              <span className="value">{paymentDetails.orderNo}</span>
            </div>

            <div className="payment-info-item">
              <span className="label">주문상품</span>
              <span className="value">{paymentDetails.productName}</span>
            </div>

            {paymentDetails.type === 'sports' && (
              <>
                <div className="payment-info-item">
                  <span className="label">장소</span>
                  <span className="value">{paymentDetails.location}</span>
                </div>
                <div className="payment-info-item">
                  <span className="label">일정</span>
                  <span className="value">{paymentDetails.date}</span>
                </div>
                <div className="payment-info-item">
                  <span className="label">시간</span>
                  <span className="value">{paymentDetails.time}</span>
                </div>
              </>
            )}

            <div className="payment-info-item payment-amount">
              <span className="label">결제금액</span>
              <span className="value">{paymentDetails.amount}</span>
            </div>
          </div>
        )}

        <div className="payment-buttons">
          <button className="home-button" onClick={handleHomeClick}>홈으로 가기</button>
          {paymentResult.success && (
            <button className="details-button" onClick={handleDetailsClick}>결제내역 가기</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentResult;
