import React from 'react';
import './CourseCard.css';

/**
 * @param {string} course            코스명 (ex: "체험", "입문+초급", "연습반" 등)
 * @param {string} time              시간 (ex: "13:00 ~ 15:00")
 * @param {number} capacity          정원
 * @param {number} participantCount  현재 예약된 인원 수
 * @param {number} waitCount         현재 대기 인원 수
 * @param {string} instructor        강사 이름
 * @param {string} sport             종목 이름 (ex: "프리다이빙")
 * @param {number} day               Day 번호 (ex: 1 → Day1)
 * @param {function} onCardClick     카드 전체 클릭 시 실행되는 핸들러
 */
function CourseCard({ 
  course, 
  time, 
  capacity, 
  participantCount,
  waitCount, 
  instructor, 
  sport, 
  day, 
  onCardClick  
}) {
  /**
   * "현재 X / 정원 Y (대기 Z명)" 형태 문자열 생성
   * 대기자 수가 0명이면 "(대기 0명)" 부분은 생략
   */
  const getParticipantInfo = () => {
    const waitInfo = waitCount > 0 ? `(대기 ${waitCount}명)` : '';
    return `정원 ${capacity}명 / 현재 ${participantCount}명 ${waitInfo}`;
  };

  /**
   * day 값이 있으면 예: "체험(Day1)"
   */
  const getCourseWithDay = () => {
    // 만약 "연습반"이면서 day를 붙이지 않으려면
    if (course === '연습반') {
      return course;
    }
    return day ? `${course}(Day${day})` : course;
  };
  

  return (
    <div className="course-card" onClick={onCardClick}>
      <div className="course-card-top">
        <span className="course">{getCourseWithDay()}</span>
        <span className="course-time">{time}</span>
        <span className="course-capacity">{getParticipantInfo()}</span>
      </div>
      <div className="course-card-bottom">
        <span className="course-instructor">{instructor} 강사</span>
        <span className="divider">/</span>
        <span className="course-sport">{sport}</span>
      </div>
    </div>
  );
}

export default CourseCard;
