import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';
import './ClassPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCheck } from '@fortawesome/free-solid-svg-icons';

function ClassPage() {
  const navigate = useNavigate();
  const [originalProducts, setOriginalProducts] = useState([]);
  const [displayProducts, setDisplayProducts] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const dropdownRef = useRef(null);
  
  // 선택된 필터 상태 추가
  const [selectedFilters, setSelectedFilters] = useState({
    class: '',
    sort: '',
    price: ''
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await api.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}/class-products/`);
        const productsWithImages = res.data.map(product => ({
          ...product,
          main_image: product.main_image.replace('/server', process.env.REACT_APP_API_URL),
        }));
        setOriginalProducts(productsWithImages);
        setDisplayProducts(productsWithImages);
      } catch (err) {
        console.error(err);
      }
    };

    fetchProducts();
  }, []);

  // 드롭다운 외부 클릭 감지
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProductClick = (id) => {
    navigate(`/class-detail/${id}`);
  };

  const toggleDropdown = (filterName) => {
    setDropdownOpen(dropdownOpen === filterName ? null : filterName);
  };

  // 필터 적용 함수
  const applyFilters = (filters) => {
    let filteredProducts = [...originalProducts];

    // 클래스 필터 적용
    if (filters.class) {
      filteredProducts = filteredProducts.filter(p => p.brand === filters.class);
    }

    // 정렬 필터 적용
    if (filters.sort === '최신순') {
      filteredProducts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (filters.sort === '리뷰순') {
      filteredProducts.sort((a, b) => b.likes - a.likes);
    }

    // 가격 필터 적용
    if (filters.price === '낮은가격순') {
      filteredProducts.sort((a, b) => a.discount_price - b.discount_price);
    } else if (filters.price === '높은가격순') {
      filteredProducts.sort((a, b) => b.discount_price - a.discount_price);
    }

    setDisplayProducts(filteredProducts);
  };

  const handleFilterSelect = (type, value) => {
    const newFilters = {
      ...selectedFilters,
      [type]: selectedFilters[type] === value ? '' : value
    };
    setSelectedFilters(newFilters);
    applyFilters(newFilters);
    setDropdownOpen(null);
  };

  // 필터 버튼 텍스트 표시 함수
  const getFilterButtonText = (type) => {
    if (selectedFilters[type]) {
      return selectedFilters[type];
    }
    switch (type) {
      case 'class':
        return '클래스';
      case 'sort':
        return '정렬';
      case 'price':
        return '가격';
      default:
        return '';
    }
  };

  return (
    <div className="shopping-page">
      <div className="filter-section">
        <div className="total-count">전체 {displayProducts.length}</div>

        <div className="filter-buttons" ref={dropdownRef}>
          <div className="filter-button-group">
            <button 
              className={`filter-button ${selectedFilters.class ? 'active' : ''}`}
              onClick={() => toggleDropdown('class')}
            >
              {getFilterButtonText('class')}
              <FontAwesomeIcon 
                icon={faAngleDown} 
                className={`icon ${dropdownOpen === 'class' ? 'active' : ''}`}
              />
            </button>
            {dropdownOpen === 'class' && (
              <div className="dropdown">
                <button 
                  onClick={() => handleFilterSelect('class', '딥스프리다이빙')}
                  className={selectedFilters.class === '딥스프리다이빙' ? 'selected' : ''}
                >
                  딥스프리다이빙
                  {selectedFilters.class === '딥스프리다이빙' && (
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                  )}
                </button>
              </div>
            )}
          </div>

          <div className="filter-button-group">
            <button 
              className={`filter-button ${selectedFilters.sort ? 'active' : ''}`}
              onClick={() => toggleDropdown('sort')}
            >
              {getFilterButtonText('sort')}
              <FontAwesomeIcon 
                icon={faAngleDown} 
                className={`icon ${dropdownOpen === 'sort' ? 'active' : ''}`}
              />
            </button>
            {dropdownOpen === 'sort' && (
              <div className="dropdown">
                {['최신순', '리뷰순'].map((option) => (
                  <button 
                    key={option}
                    onClick={() => handleFilterSelect('sort', option)}
                    className={selectedFilters.sort === option ? 'selected' : ''}
                  >
                    {option}
                    {selectedFilters.sort === option && (
                      <FontAwesomeIcon icon={faCheck} className="check-icon" />
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="filter-button-group">
            <button 
              className={`filter-button ${selectedFilters.price ? 'active' : ''}`}
              onClick={() => toggleDropdown('price')}
            >
              {getFilterButtonText('price')}
              <FontAwesomeIcon 
                icon={faAngleDown} 
                className={`icon ${dropdownOpen === 'price' ? 'active' : ''}`}
              />
            </button>
            {dropdownOpen === 'price' && (
              <div className="dropdown">
                {['낮은가격순', '높은가격순'].map((option) => (
                  <button 
                    key={option}
                    onClick={() => handleFilterSelect('price', option)}
                    className={selectedFilters.price === option ? 'selected' : ''}
                  >
                    {option}
                    {selectedFilters.price === option && (
                      <FontAwesomeIcon icon={faCheck} className="check-icon" />
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="product-grid">
          {displayProducts.map(product => (
            <div
              key={product.id}
              className="product-card"
              onClick={() => handleProductClick(product.id)}
            >
              <div className="product-image">
                <img 
                  src={product.main_image} 
                  alt={product.title}
                  onError={(e) => { e.target.src = '/images/default.jpg'; }}
                />
              </div>
              <div className="product-info">
                <div className="product-brand">{product.brand}</div>
                <h3 className="product-title">{product.title}</h3>
                <div className="product-price">
                  <div className="discount-label">할인가</div>
                  <div className="original-price">
                    {(product.original_price || 0).toLocaleString()}원
                  </div>
                  <div className="current-price">
                    <span className="discount-rate">
                      {product.discount_rate}%
                    </span>
                    &nbsp;
                    {(product.discount_price || 0).toLocaleString()}원
                  </div>
                </div>
                <div className="product-likes">리뷰 {product.likes}개</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ClassPage;
