// React 라이브러리 불러오기
import React from 'react';
// CSS 스타일시트 불러오기
import './TravelCard.css';

/**
 * 여행 정보를 표시하는 카드 컴포넌트
 * @param {string} title - 여행 제목
 * @param {string} dateRange - 여행 기간 (예: "2025.02.01 - 2025.02.05")
 * @param {string[]} guides - 인솔자 이름 배열
 * @param {function} onClick - 카드 클릭 시 실행될 함수
 * @param {number} maxParticipants - 최대 참가 인원
 * @param {number} paidCount - 확정 신청자 수
 * @param {number} unpaidCount - 대기자 수
 */
const TravelCard = ({ title, dateRange, guides, onClick, maxParticipants, paidCount, unpaidCount }) => {
  return (
    // 카드 전체를 감싸는 클릭 가능한 컨테이너
    <div className="travel-card" onClick={onClick}>
      {/* 카드 내용을 담는 컨테이너 */}
      <div className="travel-card-content">
        {/* 여행 제목 */}
        <h3 className="travel-card-title">{title}</h3>
        {/* 여행 기간 */}
        <p className="travel-card-date">{dateRange}</p>
        {/* 인솔자 정보 - guides 배열의 요소들을 쉼표로 구분하여 표시 */}
        <p className="travel-card-guides">인솔자: {guides.join(', ')}</p>
        {/* 신청 현황 - 확정 인원 / 최대 인원 (대기 인원) */}
        <p className="schedule-details">신청: {paidCount} / {maxParticipants} (대기 {unpaidCount})</p>
      </div>
    </div>
  );
};

// 다른 파일에서 TravelCard 컴포넌트를 불러올 수 있도록 내보내기
export default TravelCard;