// React 라이브러리 불러오기
import React from 'react';
// 텍스트 에디터(Quill) 사용을 위한 hook 불러오기
import { useQuill } from 'react-quilljs';
// Quill 에디터의 스타일 파일 불러오기
import 'quill/dist/quill.snow.css';
// 부트스트랩 스타일 불러오기
import 'bootstrap/dist/css/bootstrap.min.css';
// Font Awesome 아이콘 스타일 불러오기
import '@fortawesome/fontawesome-free/css/all.min.css';
// 공통 스타일 파일 불러오기
import './common.css';

/**
 * 장비 구매 글 작성을 위한 입력 폼 컴포넌트
 */
const EquipmentPurchaseInput = () => {
    // Quill 에디터 초기화 및 설정
    const { quill, quillRef } = useQuill({
        // 에디터 도구모음 설정
        modules: {
            toolbar: [
                [{ header: [1, 2, false] }],              // 제목 스타일
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],  // 텍스트 서식
                [{ list: 'ordered' }, { list: 'bullet' }], // 목록
                ['link', 'image', 'video'],               // 미디어 삽입
                ['clean']                                 // 서식 제거
            ]
        },
        // 사용 가능한 서식 지정
        formats: ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link', 'image', 'video'],
        // 에디터 테마 설정
        theme: 'snow',
    });

    // 이미지 업로드 핸들러 설정
    React.useEffect(() => {
        if (quill) {
            // 툴바의 이미지 버튼 클릭 시 처리
            quill.getModule('toolbar').addHandler('image', () => {
                // 파일 입력 엘리먼트 생성
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.click();

                // 파일 선택 시 처리
                input.onchange = () => {
                    const file = input.files[0];
                    const formData = new FormData();
                    formData.append('file', file);

                    // 서버에 이미지 업로드
                    fetch('https://example.com/upload-image', {
                        method: 'POST',
                        body: formData,
                    })
                        .then(response => response.json())
                        .then(result => {
                            // 업로드된 이미지 URL을 에디터에 삽입
                            const url = result.url;
                            const range = quill.getSelection();
                            quill.insertEmbed(range.index, 'image', url);
                        })
                        .catch(error => {
                            console.error('Image upload failed:', error);
                        });
                };
            });
        }
    }, [quill]);

    return (
        // 입력 폼 컨테이너
        <div className="input-container">
            {/* 페이지 제목 */}
            <h2 className="input-section-title">장비 구매 입력</h2>
            
            {/* 입력 폼 그룹 */}
            <div className="input-form-group">
                {/* 장비명과 브랜드 입력 행 */}
                <div className="input-row">
                    <div className="input-item">
                        <label>장비</label>
                        <input type="text" className="equipmentinput-form-control" placeholder="장비명을 입력하세요" />
                    </div>
                    <div className="input-item">
                        <label>브랜드</label>
                        <input type="text" className="equipmentinput-form-control" placeholder="브랜드명을 입력하세요" />
                    </div>
                </div>

                {/* 판매자와 가격 입력 행 */}
                <div className="input-row">
                    <div className="input-item">
                        <label>판매자</label>
                        <input type="text" className="equipmentinput-form-control" placeholder="판매자명을 입력하세요" />
                    </div>
                    <div className="input-item">
                        <label>가격 (원)</label>
                        <input type="number" className="equipmentinput-form-control" placeholder="가격을 입력하세요" />
                    </div>
                </div>

                {/* 커버 이미지 업로드 행 */}
                <div className="input-row">
                    <div className="input-item">
                        <label>커버<br/>이미지</label>
                        <input type="file" className="equipmentinput-form-control" />
                    </div>
                </div>

                {/* 상세 설명 에디터 영역 */}
                <div className="equipmentinput-form-group-detailed">
                    <label>상세 설명</label>
                    <div>
                        <div ref={quillRef} />
                    </div>
                </div>

                {/* 저장 버튼 */}
                <div className="equipmentinput-div-input-save">
                    <button className="equipmentinput-btn btn-primary save-button">저장</button>
                </div>
            </div>
        </div>
    );
};

export default EquipmentPurchaseInput;