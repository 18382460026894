import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TourPage.css';

const travelPackages = [
  {
    id: 1,
    title: '터키에 9,10,12일 #현지 국내선 2회',
    category: '동글호텔',
    image: 'https://images.unsplash.com/photo-1499856871958-5b9627545d1a?w=500',
    price: '2,959,000원~'
  },
  {
    id: 2,
    title: '서유럽 3개국 8~11일 #알프스 여행 #로마 벤츠투어',
    category: '세느강 유람선',
    image: 'https://images.unsplash.com/photo-1499856871958-5b9627545d1a?w=500',
    price: '4,039,000원~'
  },
  {
    id: 3,
    title: '규슈 3,4일 #100% 출발확정 #온천여행',
    category: '후쿠오카,유후인 맛부',
    image: 'https://images.unsplash.com/photo-1492571350019-22de08371fd3?w=500',
    price: '429,900원~'
  },
  {
    id: 4,
    title: '스페인/포르투갈 9,10,11일 #시내 호텔',
    category: '몬세라트 케이블카',
    image: 'https://images.unsplash.com/photo-1558642084-fd07fae5282e?w=500',
    price: '3,599,000원~'
  },
  {
    id: 5,
    title: '이탈리아 일주 8일 #로마 콜로세움',
    category: '로마 시내투어',
    image: 'https://images.unsplash.com/photo-1552832230-c0197dd311b5?w=500',
    price: '3,299,000원~'
  },
  {
    id: 6,
    title: '프랑스 파리 7일 #에펠탑 야경',
    category: '파리 시내투어',
    image: 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?w=500',
    price: '2,899,000원~'
  },
  {
    id: 7,
    title: '스위스 알프스 6일 #융프라우',
    category: '알프스 트레킹',
    image: 'https://images.unsplash.com/photo-1531366936337-7c912a4589a7?w=500',
    price: '3,799,000원~'
  },
  {
    id: 8,
    title: '그리스 산토리니 5일 #에게해',
    category: '산토리니 투어',
    image: 'https://images.unsplash.com/photo-1613395877344-13d4a8e0d49e?w=500',
    price: '2,499,000원~'
  }
];

function TourPage() {
  const navigate = useNavigate();

  const handlePackageClick = (id) => {
    navigate(`/shopping-detail/${id}`);
  };

  return (
    <div className="shopping-page">
      
      <div className="search-bar">
        <input 
          type="text" 
          placeholder="떠나고 싶은 여행지가 있나요?"
          className="search-input"
        />
      </div>

      <div className="package-grid">
        {travelPackages.map(pkg => (
          <div key={pkg.id} className="package-card" onClick={() => handlePackageClick(pkg.id)}>
            <div className="package-image">
              <img src={pkg.image} alt={pkg.title} />
              <span className="package-category">{pkg.category}</span>
            </div>
            <div className="package-info">
              <h3>{pkg.title}</h3>
              <p className="package-price">{pkg.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TourPage;