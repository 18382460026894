// React와 라우터 기능을 위한 라이브러리 불러오기
import React from 'react';
import { Link } from 'react-router-dom';
// CSS 스타일시트 불러오기
import './BottomNavigation.css';

/**
 * 화면 하단에 표시되는 네비게이션 바 컴포넌트
 * 클래스, 여행, 쇼핑 메뉴로 구성됨
 */
function BottomNavigation() {
  return (
    // 하단 네비게이션 바의 최상위 요소
    <nav className="bottom-navigation">
      {/* 네비게이션 아이템들을 감싸는 컨테이너 */}
      <div className="bottom-nav-container">
        {/* 클래스 메뉴 링크 */}
        <Link to="/" className="bottom-nav-link-item">
          <img
            // process.env.PUBLIC_URL을 사용하여 public 폴더 내의 이미지 경로 지정
            src={`${process.env.PUBLIC_URL}/images/icon_bottom/home_ico.svg`}
            alt="Home"
            className="bottom-nav-icon"
          />
          <span>Home</span>
        </Link>
      
        {/* 클래스 메뉴 링크 */}
        <Link to="/class" className="bottom-nav-link-item">
          <img
            // process.env.PUBLIC_URL을 사용하여 public 폴더 내의 이미지 경로 지정
            src={`${process.env.PUBLIC_URL}/images/icon_bottom/class_ico.svg`}
            alt="클래스"
            className="bottom-nav-icon"
          />
          <span>클래스</span>
        </Link>

        {/* 여행 메뉴 링크 */}
        <Link to="/tour" className="bottom-nav-link-item">
          <img
            src={`${process.env.PUBLIC_URL}/images/icon_bottom/travel_ico.svg`}
            alt="여행"
            className="bottom-nav-icon"
          />
          <span>여행</span>
        </Link>

        {/* 쇼핑 메뉴 링크 */}
        <Link to="/shopping" className="bottom-nav-link-item">
          <img
            src={`${process.env.PUBLIC_URL}/images/icon_bottom/shopping_ico.svg`}
            alt="쇼핑"
            className="bottom-nav-icon"
          />
          <span>쇼핑</span>
        </Link>

        {/* 나의 링크 */}
        <Link to="/user-profile" className="bottom-nav-link-item">
          <img
            src={`${process.env.PUBLIC_URL}/images/icon_bottom/my_ico.svg`}
            alt="MY"
            className="bottom-nav-icon"
          />
          <span>MY</span>
        </Link>
      </div>
    </nav>
  );
}

// 다른 파일에서 BottomNavigation 컴포넌트를 불러올 수 있도록 내보내기
export default BottomNavigation;