// React와 필요한 라이브러리, 유틸리티 파일을 임포트
import React, { useState, useEffect } from 'react'; // React의 상태와 생명주기 관련 훅
import Modal from 'react-modal'; // React 모달 라이브러리
import { Link, useNavigate } from 'react-router-dom'; // 페이지 이동(Link와 useNavigate) 관련 라이브러리
import api from '../utils/api'; // API 요청을 처리하는 커스텀 Axios 인스턴스
import Cookies from 'js-cookie'; // 쿠키 관리 라이브러리
import './LoginModal.css'; // 로그인 모달에 대한 스타일

// LoginModal 컴포넌트 정의
// props: isOpen(모달 열림 여부), onClose(모달 닫기 함수), setUserId(사용자 ID 업데이트), setLevel(사용자 레벨 업데이트)
const LoginModal = ({ isOpen, onClose, setUserId, setLevel }) => {
  // 상태 관리
  const [userId, setUserIdLocal] = useState(''); // 로컬에서 관리하는 사용자 ID
  const [password, setPassword] = useState(''); // 비밀번호 입력 상태
  const [errors, setErrors] = useState({}); // 입력 폼의 에러 상태
  const navigate = useNavigate(); // 페이지 이동을 위한 React Router 훅

  // 컴포넌트가 처음 렌더링될 때 실행되는 코드
  useEffect(() => {
    // 이전에 저장된 사용자 ID를 로컬 저장소에서 불러옴 (자동 완성을 위한 기능)
    const rememberedUserId = localStorage.getItem('rememberedUserId');
    if (rememberedUserId) {
      setUserIdLocal(rememberedUserId);
    }
  }, []); // 빈 배열([])은 한 번만 실행됨

  // 필수 입력 필드와 오류 메시지를 정의
  const requiredFields = [
    { field: 'userId', message: '아이디를 입력해주세요' },
    { field: 'password', message: '비밀번호를 입력해주세요' },
  ];

  // 입력값 검증 함수
  const validateFields = () => {
    const newErrors = {}; // 에러 객체 초기화
    requiredFields.forEach(({ field, message }) => {
      // 각 필드가 비어 있는 경우 에러 메시지 추가
      if (!eval(field).trim()) {
        newErrors[field] = message;
      }
    });
    setErrors(newErrors); // 에러 상태 업데이트
    return Object.keys(newErrors).length === 0; // 에러가 없으면 true 반환
  };

  // 로그인 버튼 클릭 시 실행되는 함수
  const handleLogin = async () => {
    const newErrors = {}; // 에러 객체 초기화
    requiredFields.forEach(({ field, message }) => {
      // 각 필드가 비어 있는 경우 에러 메시지 추가
      if (!eval(field).trim()) {
        newErrors[field] = message;
      }
    });

    // 에러가 있는 경우 경고창 표시 및 처리 중단
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors).join('\n'); // 에러 메시지 합치기
      alert(errorMessages); // 경고창으로 에러 표시
      setErrors(newErrors); // 에러 상태 업데이트
      return;
    }

    // API를 통해 서버로 로그인 요청
    try {
      const response = await api.post('/deeps_user/auth/', {
        user_id: userId, // 사용자 ID
        password: password, // 비밀번호
      });

      // 로그인 성공 시 처리
      if (response.data.code === '0000') {
        const accessToken = response.data.token.access; // 액세스 토큰
        const refreshToken = response.data.token.refresh; // 리프레시 토큰
        const level = response.data.user.level; // 사용자 레벨

        // 로컬 저장소 초기화 및 사용자 정보 저장
        localStorage.clear();
        localStorage.setItem('accessToken', accessToken); // 액세스 토큰 저장
        Cookies.set('refreshToken', refreshToken, { secure: true, sameSite: 'Strict' }); // 쿠키에 리프레시 토큰 저장
        localStorage.setItem('userId', userId); // 사용자 ID 저장
        localStorage.setItem('rememberedUserId', userId); // 사용자 ID 기억
        localStorage.setItem('level', level); // 사용자 레벨 저장

        setUserId(userId); // 상위 컴포넌트에 사용자 ID 전달
        setLevel(level); // 상위 컴포넌트에 사용자 레벨 전달
        onClose(); // 모달 닫기
        navigate('/'); // 메인 페이지로 이동
      } else {
        // 로그인 실패 시 처리
        console.log('로그인 실패:', response.data.message);
        alert('로그인에 실패했습니다. 아이디와 비밀번호를 확인해주세요.');
      }
    } catch (error) {
      // 서버 요청 중 에러 발생 시 처리
      console.error('로그인 중 오류 발생:', error);
      alert('로그인에 실패했습니다. 아이디와 비밀번호를 확인해주세요.');
    }
  };

  // 모달 UI
  return (
    <Modal
      isOpen={isOpen} // 모달 열림 여부
      onRequestClose={onClose} // 모달 닫기 함수
      className="login_ReactModal__Content" // 모달 콘텐츠에 대한 CSS 클래스
      overlayClassName="login-ReactModal__Overlay" // 모달 오버레이에 대한 CSS 클래스
    >
      <div className="login-modal-content">
        <div className="login-modal-header">
          <h2>로그인</h2> {/* 모달 제목 */}
        </div>
        <div className="login-modal-body">
          {/* 사용자 ID 입력 */}
          <div className="input-group">
            <label>ID</label>
            <input
              type="text"
              value={userId} // 입력값
              onChange={(e) => setUserIdLocal(e.target.value)} // 입력값 변경 시 상태 업데이트
              placeholder="아이디를 입력해 주세요." // 입력 안내 문구
              className="modal-input" // 스타일 적용을 위한 클래스
            />
          </div>
          {/* 비밀번호 입력 */}
          <div className="input-group">
            <label>PW</label>
            <input
              type="password"
              value={password} // 입력값
              onChange={(e) => setPassword(e.target.value)} // 입력값 변경 시 상태 업데이트
              placeholder="패스워드를 입력해 주세요." // 입력 안내 문구
              className="modal-input" // 스타일 적용을 위한 클래스
            />
          </div>
          {/* 로그인 버튼 */}
          <div className="login-modal-footer">
            <button onClick={handleLogin} className="modal-button">
              로그인
            </button>
          </div>
          {/* 회원가입 및 비밀번호 찾기 링크 */}
          <div className="modal-links">
            <Link to="/signup" onClick={onClose} className="signup-link">
              회원가입
            </Link>
            <span className="link-separator">|</span>
            <Link onClick={onClose} className="find-password-link">
              비밀번호찾기
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal; // LoginModal 컴포넌트를 외부에서 사용할 수 있도록 내보냄
