import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './common.css';
import './SportsLearning.css';
import api from '../utils/api';
import CourseCard from '../components/CourseCard';

/** 
 * (A) 사용자 예약/대기 상태 파악
 */
function getReservationStatus(schedule, currentUserId) {
  // "연습반" 또는 "정기 트레이닝"일 경우는 practice_participants를 사용합니다.
  const isPracticeCourse = (schedule.course === '연습반' || schedule.course === '정기 트레이닝');

  const participants = isPracticeCourse 
    ? schedule.practice_participants || [] 
    : schedule.apply_participants || [];

  const waiters = isPracticeCourse
    ? schedule.practice_wait_participants || []
    : schedule.wait_participants || [];

  if (participants.some(p => (p.id === currentUserId || p.user_id === currentUserId))) {
    return 'APPLIED';  // 예약 완료
  }
  
  if (waiters.some(w => (w.id === currentUserId || w.user_id === currentUserId))) {
    return 'WAITING';  // 대기 상태
  }

  return 'NONE';  // 아무 상태도 아닐 때
}


const MAX_RESERVATION = 10;

const SportsLearning = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // 필터
  const selectedLocation = location.state?.location || '';
  const selectedSport = location.state?.selectedSport || '';

  // Tab, Date
  const [selectedTab, setSelectedTab] = useState("전체");
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());

  // 스케줄 목록
  const [schedules, setSchedules] = useState({});
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const scheduleRefs = useRef({});

  // 사용자 / 레벨 / 결제완료 목록
  const userLevel = localStorage.getItem('level');  
  const currentUserId = localStorage.getItem('userId'); 
  const isInstructor = (userLevel === '11');
  const [paidBaseCourseIds, setPaidBaseCourseIds] = useState(new Set());

  // **(추가)** 사용자 정보 (이메일, 연락처) - 결제 시 필요
  const [userInfo, setUserInfo] = useState({ name: '', email: '', phone: '' });

  const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

  /* -------------------------
   * 1) 마운트 시
   * ------------------------- */
  useEffect(() => {
    fetchUserInfo();
    fetchPaidCourses();
  }, []);

  /* -------------------------
   * 2) 탭/스포츠/장소 바뀌면
   * ------------------------- */
  useEffect(() => {
    if (selectedSport) {
      fetchSchedules();
    }
    // eslint-disable-next-line
  }, [selectedSport, selectedLocation, selectedTab]);

  /** [A] 사용자 정보 불러오기 (이름/이메일/전화번호) */
  const fetchUserInfo = async () => {
    try {
      const storedUserId = localStorage.getItem('userId');
      if (!storedUserId) return; // 비로그인 etc.
      // 예: GET /deeps_user/api/v1/user-info/:userId
      const res = await api.get(`deeps_user/api/v1/user-info/${storedUserId}`);
      setUserInfo({
        name: res.data.name,
        email: res.data.user_email,
        phone: res.data.hp
      });
    } catch (error) {
      console.error('사용자 정보 가져오기 실패:', error);
    }
  };

  /** [B] 결제 완료 목록 */
  const fetchPaidCourses = async () => {
    try {
      const res = await api.get(`${API_BASE_PATH}/user-purchases`);
      const sportsPurchases = res.data?.sports_purchases || [];
      const baseIds = new Set(sportsPurchases.map(item => item.base_course_id));
      setPaidBaseCourseIds(baseIds);
    } catch (error) {
      console.error("결제 목록 가져오기 실패:", error);
    }
  };

  /** [C] 스케줄 목록 불러오기 */
  const fetchSchedules = async () => {
    try {
      const params = {
        sport: (selectedSport !== '전체') ? selectedSport : '',
        location: (selectedLocation !== '전체') ? selectedLocation : '',
      };
      const response = await api.get(`${API_BASE_PATH}/sports-courses`, { params });
      let schedulesData = response.data;

      if (selectedTab === "연습반") {
        schedulesData = schedulesData
          .filter(sch => sch.total_practice_participants > 0)
          .map(sch => ({ ...sch, course: "연습반" }));
      } else if (selectedTab !== "전체") {
        schedulesData = schedulesData.filter(sch => sch.course === selectedTab);
      }

      // courseName group
      const grouped = schedulesData.reduce((acc, cur) => {
        const cname = cur.course;
        if (!acc[cname]) acc[cname] = [];
        acc[cname].push(cur);
        return acc;
      }, {});
      
      setSchedules(grouped);
    } catch (error) {
      console.error('스케줄 가져오기 실패:', error);
    }
  };

  /* -------------------------
   * D. 탭 변경
   * ------------------------- */
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setSelectedSchedule(null);
  };

  /* -------------------------
   * E. 강사용 스케줄 생성
   * ------------------------- */
  const handleCreateSchedule = () => {
    // 현재 선택된 스포츠가 있으면 쿼리 파라미터로 전달
    if (selectedSport && selectedSport !== '전체') {
      navigate(`/input?sport=${selectedSport}`);
    } else {
      navigate('/input');
    }
  };

  /* -------------------------
   * F. 카드 열기/닫기
   * ------------------------- */
  const handleCardClick = (schedule) => {
    if (selectedSchedule && selectedSchedule.id === schedule.id) {
      setSelectedSchedule(null);
    } else {
      setSelectedSchedule(schedule);
      setTimeout(() => {
        scheduleRefs.current[schedule.id]?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 100);
    }
  };

  /* -------------------------
   * G. 상세보기
   * ------------------------- */
  const handleDetailClick = (schedule) => {
    navigate(`/schedule/${schedule.id}`, { state: { schedule } });
  };

  /* -------------------------
   * H. 예약/대기
   * ------------------------- */
  // const handleReservation = async (schedule, isPractice, isWait = false) => {
  //   try {
  //     const scheduleId = schedule.id;
  
  //     let requestData = {
  //       is_practice_waits: isWait,
  //     };
  
  //     if (isPractice) {
  //       // 연습반 예약/대기
  //       requestData = {
  //         ...requestData,
  //         is_free_practice: true,  // 연습반을 위한 추가 필드
  //       };
  //     } else {
  //       // 일반 강습 예약/대기 (연습반이 아닌 경우)
  //       requestData = {
  //         ...requestData,
  //         is_free_practice: false, // 연습반이 아니면 false
  //       };
  //     }
  
  //     // API 요청 보내기
  //     const res = await api.post(`${API_BASE_PATH}/apply-session/${scheduleId}/`, requestData);
  
  //     if (res.data.code === '0000') {
  //       alert(isWait ? "대기 등록 완료" : "예약 완료");
  //       fetchSchedules(); // 예약 완료 후 스케줄 목록 갱신
  //     } else {
  //       alert(`예약 실패: ${res.data.message || '알 수 없는 오류'}`);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     alert("예약 요청 실패");
  //   }
  // };

  const handleReservation = async (schedule, isPractice, isWait = false) => {
    try {
      const scheduleId = schedule.id;
  
      let requestData = {
        is_practice_waits: isWait,
        is_free_practice: isPractice,
      };
  
      const res = await api.post(`${API_BASE_PATH}/apply-session/${scheduleId}/`, requestData);
  
      if (res.data.code === '0000') {
        alert(isWait ? "대기 등록 완료" : "예약 완료");
        fetchSchedules();
      } else {
        alert(`예약 실패: ${res.data.message || '알 수 없는 오류'}`);
      }
    } catch (error) {
      console.error(error);
      alert(error.response?.data.detail || error.message || "예약 요청 실패");
    }
  };

  /* -------------------------
   * I. 예약취소/대기취소
   * ------------------------- */
  const handleCancelReservation = async (schedule, isPractice, isWait = false) => {
    try {
      const scheduleId = schedule.id;
      const res = await api.delete(`${API_BASE_PATH}/cancel-session/${scheduleId}/`, {
        params: {
          is_free_practice: isPractice,
          is_practice_waits: isWait
        }
      });
      if (res.data.code === '0000') {
        alert(isWait ? "대기 취소 완료" : "예약 취소 완료");
        fetchSchedules();
      } else {
        alert(`취소 실패: ${res.data.message || '알 수 없는 오류'}`);
      }
    } catch (error) {
      console.error(error);
      alert(error.response?.data.detail || error.message || "취소 요청 실패");
    }
  };

  /* -------------------------
   * J. 달력 생성
   * ------------------------- */
  const generateCalendarDays = () => {
    const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
    const weekLabels = (
      <div className="calendar-week-labels">
        {daysOfWeek.map((day) => <div key={day} className="calendar-week-day">{day}</div>)}
      </div>
    );

    const days = [];
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const firstDayIndex = firstDay.getDay();

    // 첫 주 빈칸
    for (let i = 0; i < firstDayIndex; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    // 날짜 클릭 핸들러
    const handleDateClick = (day) => {
      setSelectedDate(day);
      setSelectedSchedule(null);
      
      // 선택한 날짜의 첫 번째 스케줄을 찾아 스크롤
      setTimeout(() => {
        const selectedDaySchedules = Object.values(schedules).flat().filter(sch => {
          const [yy, mm, dd] = sch.date.split('-').map(Number);
          return (
            yy === currentDate.getFullYear() &&
            mm === currentDate.getMonth() + 1 &&
            dd === day
          );
        });
        
        if (selectedDaySchedules.length > 0) {
          const firstSchedule = selectedDaySchedules[0];
          if (scheduleRefs.current[firstSchedule.id]) {
            scheduleRefs.current[firstSchedule.id].current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        }
      }, 100);
    };

    // 1일부터 마지막 일까지
    for (let day = 1; day <= lastDay.getDate(); day++) {
      const isSelected = (selectedDate === day);
      const hasSchedule = Object.values(schedules).flat().some(sch => {
        const [yy, mm, dd] = sch.date.split('-').map(Number);
        return (
          yy === currentDate.getFullYear() &&
          mm === currentDate.getMonth() + 1 &&
          dd === day
        );
      });

      days.push(
        <div
          key={day}
          className={`calendar-day ${isSelected ? 'selected' : ''}`}
          onClick={() => handleDateClick(day)}
        >
          <div className="day-number">{day}</div>
          {hasSchedule && <div className="schedule-dots"><span></span></div>}
        </div>
      );
    }

    return (
      <div className="calendar-container">
        {weekLabels}
        <div className="calendar">{days}</div>
      </div>
    );
  };

  /* -------------------------
   * K. 렌더
   * ------------------------- */
  return (
    <div className="main-content">
      {/* 헤더 */}
      <div className="header">
        {isInstructor && (
          <div className="instructor-button-container">
            <button className="schedule-create-button" onClick={handleCreateSchedule}>
              스케줄 생성
            </button>
          </div>
        )}
        <div className="tab-container">
          {["전체", "연습반", "입문+초급", "체험", "중급", "정기 트레이닝", "특강", "고급"].map(tab => (
            <span
              key={tab}
              className={`tab-item ${selectedTab === tab ? 'active' : ''}`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </span>
          ))}
        </div>
      </div>

      {/* 달력 */}
      <div className="calendar-section">
        <div className="month-selector">
          <button
            className="prev-month"
            onClick={() => {
              setSelectedDate(null);
              setSelectedSchedule(null);
              setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth()-1, 1));
            }}
          >
            ‹
          </button>
          <span>{`${currentDate.getFullYear()}년 ${currentDate.getMonth()+1}월`}</span>
          <button
            className="next-month"
            onClick={() => {
              setSelectedDate(null);
              setSelectedSchedule(null);
              setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 1));
            }}
          >
            ›
          </button>
        </div>
        {generateCalendarDays()}
      </div>

      {/* 스케줄 목록 */}
      {Object.keys(schedules).length === 0 ? (
        <p>스케줄이 없습니다.</p>
      ) : (
        Object.keys(schedules).map(courseName => {
          const filteredSchedules = schedules[courseName].filter(sch => {
            const [yy, mm, dd] = sch.date.split('-').map(Number);
            const scheduleDate = new Date(yy, mm-1, dd);

            if (selectedDate) {
              return (
                scheduleDate.getFullYear() === currentDate.getFullYear() &&
                scheduleDate.getMonth() === currentDate.getMonth() &&
                scheduleDate.getDate() === selectedDate
              );
            } else {
              // 오늘 이후
              const today = new Date();
              today.setHours(0,0,0,0);
              return scheduleDate >= today;
            }
          });

          if (filteredSchedules.length === 0) return null;

          return (
            <div key={courseName} className="course-group">
              <div className="schedule-list">
                {filteredSchedules.map(schedule => {
                  const isSelected = (selectedSchedule && selectedSchedule.id === schedule.id);

                  const isPracticeCourse = (
                    schedule.course === '연습반' ||
                    schedule.course === '정기 트레이닝'
                  );

                  const participantCount = isPracticeCourse
                    ? (schedule.practice_participants?.length || 0)
                    : (schedule.apply_participants?.length || 0);

                  const waitCount = isPracticeCourse
                    ? (schedule.practice_wait_participants?.length || 0)
                    : (schedule.wait_participants?.length || 0);

                  const isFull = (participantCount >= MAX_RESERVATION);
                  const reservationStatus = getReservationStatus(schedule, currentUserId);

                  // base_course_id
                  const baseCourseId = schedule.base_course_id;
                  const isPaidCourse = paidBaseCourseIds.has(baseCourseId);

                  if (!scheduleRefs.current[schedule.id]) {
                    scheduleRefs.current[schedule.id] = React.createRef();
                  }

                  return (
                    <div
                      className="card-button-block"
                      key={schedule.id}
                      ref={scheduleRefs.current[schedule.id]}
                    >
                      <CourseCard
                        course={schedule.course}
                        time={schedule.time}
                        capacity={isPracticeCourse
                          ? schedule.total_practice_participants
                          : schedule.total_participants
                        }
                        participantCount={participantCount}
                        waitCount={waitCount}
                        instructor={schedule.instructor.name}
                        sport={schedule.sport}
                        day={schedule.day}
                        onCardClick={() => handleCardClick(schedule)}
                      />

                      {isSelected && (
                        <div className="button-bar">
                          <button 
                            className="detail-btn"
                            onClick={() => handleDetailClick(schedule)}
                          >
                            상세보기
                          </button>

                          {reservationStatus === 'APPLIED' ? (
                            <button
                              className="pay-btn"
                              onClick={() => handleCancelReservation(schedule, isPracticeCourse, false)}
                            >
                              예약 취소하기
                            </button>
                          ) : reservationStatus === 'WAITING' ? (
                            <button
                              className="pay-btn"
                              onClick={() => handleCancelReservation(schedule, isPracticeCourse, true)}
                            >
                              대기 취소하기
                            </button>
                          ) : (
                            <>
                              {isPracticeCourse ? (
                                isFull ? (
                                  <button
                                    className="pay-btn"
                                    onClick={() => handleReservation(schedule, true, true)}
                                  >
                                    대기 예약
                                  </button>
                                ) : (
                                  <button
                                    className="pay-btn"
                                    onClick={() => handleReservation(schedule, true, false)}
                                  >
                                    예약하기
                                  </button>
                                )
                              ) : (
                                // 일반 강습
                                isFull ? (
                                  <button
                                    className="pay-btn"
                                    onClick={() => handleReservation(schedule, false, true)}
                                  >
                                    대기 예약(강습)
                                  </button>
                                ) : (
                                  <button
                                    className="pay-btn"
                                    onClick={() => handleReservation(schedule, false, false)}
                                  >
                                    예약하기
                                  </button>
                                )
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default SportsLearning;