import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';

function Navigation({ onLoginClick, userId }) {
  return (
    <nav className="navigation">
      <div className="nav-container">
        
        {/* 로고를 클릭하면 메인 페이지로 이동 */}
        <Link to="/" className="nav-logo">
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="DEEPS" />
        </Link>

        {/* 사용자 정보 표시: 로그인 여부에 따라 다르게 렌더링 */}
        {userId ? (
          // [로그인 상태]: 아무것도 표시하지 않음
          null
        ) : (
          // [비로그인 상태]: 로그인 버튼 표시
          <button className="nav-login" onClick={onLoginClick}>
            <span>로그인</span>
          </button>
        )}
      </div>
    </nav>
  );
}

export default Navigation;
