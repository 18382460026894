import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowLeft, 
  faShareAlt, 
  faChevronLeft, 
  faChevronRight,
  faHeart as faHeartSolid,
  faAngleUp,
  faAngleDown,
  faCalendarAlt,
  faMapMarkerAlt,
  faUsers,
  faMinus,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import api from '../utils/api';
import './ClassPageDetail.css';

function ProductDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isDetailExpanded, setIsDetailExpanded] = useState(false);
  const [showFloatingHeader, setShowFloatingHeader] = useState(false);
  const [hideNavigation, setHideNavigation] = useState(false);
  
  // 선택된 탭 상태
  const [activeTab, setActiveTab] = useState('detail');
  
  // 상세정보 더보기 상태
  const [showFullDetail, setShowFullDetail] = useState(false);
  
  // 상품 옵션 상태
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(''); // 기본값 없이 시작
  const [selectedDate, setSelectedDate] = useState('');
  const [quantity, setQuantity] = useState(1);
  
  // 강습권만 구매하는 옵션 상태 추가
  const [voucherOnly, setVoucherOnly] = useState(false);
  
  // 오류 처리를 위한 상태 추가
  const [apiError, setApiError] = useState('');
  const [loadingDates, setLoadingDates] = useState(false);
  
  // 사용자 정보 상태
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    phone: ''
  });
  
  // 옵션 데이터 (API에서 가져올 데이터)
  const [locations, setLocations] = useState([]);
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // 하드코딩 데이터 (백엔드 확장 전까지 사용)
  const [reviews] = useState([
    { id: 1, author: '김수영', rating: 5, content: '정말 좋은 경험이었습니다. 강사님이 친절하고 전문적이었어요.' },
    { id: 2, author: '이바다', rating: 4, content: '처음 도전해보는 프리다이빙이었는데 재미있었습니다.' }
  ]);
  
  // 하드코딩된 반품/교환 정보
  const returnPolicy = {
    title: 'BUCCL 버클 반품/교환 안내',
    description: '반품 시 먼저 판매자와 연락하셔서 반품사유, 택배사, 배송비, 반품지 주소 등을 협의하신 후 반품상품을 발송해 주시기 바랍니다.',
    return_period: [
      '구매자 단순 변심은 상품 수령 후 7일 이내 (구매자 반품배송비 부담)',
      '표시/광고와 상이, 계약 내용과 다르게 이행된 경우 상품 수령 후 3개월 이내 혹은 표시/광고와 다른 사실을 안 날로부터 30일 이내 (판매자 반품 배송비 부담)',
      '둘 중 하나 경과 시 반품/교환 불가'
    ],
    return_reasons: [
      '반품요청기간이 지난 경우',
      '구매자의 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우 (단, 상품의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외)',
      '구매자의 책임있는 사유로 포장이 훼손되어 상품 가치가 현저히 상실된 경우 (예: 식품, 화장품, 향수류, 음반 등)',
      '구매자의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우 (라벨이 떨어지거나 의류 또는 태그가 떨어진 명품과 상품인 경우)',
      '시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우'
    ],
    seller_info: {
      company_name: '주식회사 버킷리스트 클래스',
      business_type: '사업자 / 법인 사업자',
      representative: '박동환,문용배'
    }
  };

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // 최소 스와이프 거리 설정
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      nextImage();
    }
    if (isRightSwipe) {
      prevImage();
    }
  };

  const [allImages, setAllImages] = useState([]);
  const [autoSlideInterval, setAutoSlideInterval] = useState(null);

  // 강습 장소 데이터 가져오기
  const fetchLocations = async () => {
    try {
      // locations/ API는 등록된 모든 위치를 가져옵니다
      // 백엔드에서 강습이 존재하는 장소만 필터링하여 제공합니다
      const response = await api.get(`${process.env.REACT_APP_API_BASE_PATH}/locations/`);
      setLocations(response.data || []);
    } catch (err) {
      console.error('강습 장소 가져오기 실패:', err);
      setLocations([]);
    }
  };

  // 강습 과정을 제목에서 추출하는 함수
  const extractCourseFromTitle = (title) => {
    // 제목 예시: "프리다이빙 입문+초급", "서핑 중급", "스쿠버다이빙 고급" 등
    const coursePatterns = ['입문\\+초급', '입문', '초급', '중급', '고급', 'AIDA[1-4]\\s*Star'];
    const courseRegex = new RegExp(`(${coursePatterns.join('|')})`, 'i');
    
    const match = title.match(courseRegex);
    if (match && match[0]) {
      return match[0].trim();
    }
    
    // 기본값 반환 (빈 문자열 대신 입문+초급으로 설정)
    return '입문+초급';
  };

  // 표준화된 코스 타입으로 변환하는 함수
  const getStandardizedCourseType = (courseType) => {
    const courseMapping = {
      '입문+초급': 'beginner',
      '입문': 'intro',
      '초급': 'beginner',
      '중급': 'intermediate',
      '고급': 'advanced'
    };
    
    return courseMapping[courseType] || 'beginner';
  };

  // 제품 제목에서 스포츠 이름을 추출하는 함수 추가
  const extractSportFromTitle = (title) => {
    if (!title) return "프리다이빙"; // 기본값
    
    // 스포츠 종류 목록 (필요에 따라 추가 가능)
    const sportsList = ["프리다이빙", "서핑", "스쿠버다이빙", "윈드서핑", "스키", "스노우보드"];
    
    // 제목에서 스포츠 종류 검색
    for (const sport of sportsList) {
      if (title.includes(sport)) {
        return sport;
      }
    }
    
    // 스포츠 이름을 발견하지 못한 경우, 공백 기준으로 첫 단어 반환
    // (일반적으로 "[스포츠이름] [과정]" 형태일 것이라 가정)
    const firstWord = title.split(' ')[0];
    if (firstWord && firstWord.length > 1) {
      return firstWord;
    }
    
    return "프리다이빙"; // 기본값
  };

  // 강습 날짜 데이터 가져오기
  const fetchDates = async (courseType, location) => {
    try {
      // 로딩 상태 설정 및 이전 오류 초기화
      setLoadingDates(true);
      setApiError('');
      
      // API 요청 파라미터 구성
      let url = `${process.env.REACT_APP_API_BASE_PATH}/sports-courses/`;
      const params = new URLSearchParams();
      
      // 과정 타입이 있는 경우 추가
      if (courseType) {
        params.append('course', courseType);
      }
      
      // 장소가 있는 경우 추가
      if (location) {
        params.append('location', location);
      }
      
      // 파라미터가 있으면 URL에 추가
      if (params.toString()) {
        url += `?${params.toString()}`;
      }
      
      console.log('요청 URL:', url);
      
      // API 호출
      const response = await api.get(url);
      
      const sportsCourses = response.data || [];
      console.log(`강습 코스 데이터 (${courseType || '전체'}, ${location || '전체'}):`, sportsCourses);
      
      // 데이터가 없는 경우
      if (sportsCourses.length === 0) {
        console.log(`${location || '전체'} 위치의 ${courseType || '전체'} 코스에 등록된 일정이 없습니다.`);
        setApiError('선택한 조건에 맞는 강습 일정이 없습니다. 다른 옵션을 선택해보세요.');
        setDates([]);
        return;
      }
      
      // 날짜와 시간 데이터 추출 및 중복 제거
      const uniqueDateTimes = [];
      const dateTimeSet = new Set();
      
      sportsCourses.forEach(course => {
        // 날짜와 시간이 모두 존재하는 경우에만 처리
        if (course.date) {
          const timeStr = course.start_time || '00:00';
          const dateTimeKey = `${course.date}T${timeStr}`;
          
          if (!dateTimeSet.has(dateTimeKey)) {
            dateTimeSet.add(dateTimeKey);
            uniqueDateTimes.push({
              key: dateTimeKey,
              date: course.date,
              time: timeStr,
              courseId: course.id, // 나중에 결제 처리 시 필요할 수 있음
              locationName: course.location || location
            });
          }
        }
      });
      
      // 날짜 오름차순 정렬
      uniqueDateTimes.sort((a, b) => new Date(a.key) - new Date(b.key));
      
      console.log('추출된 날짜 및 시간 목록:', uniqueDateTimes);
      
      setDates(uniqueDateTimes);
      
      // 날짜 변경 시 선택된 날짜 초기화
      setSelectedDate('');
      
    } catch (err) {
      console.error('강습 날짜 가져오기 실패:', err);
      if (err.response) {
        console.error('에러 상태:', err.response.status);
        console.error('에러 응답:', err.response.data);
      }
      
      // 오류 메시지 처리
      if (err.response?.data?.code === '1006' && 
          err.response?.data?.message?.includes('BaseSportsCourse matching query does not exist')) {
        setApiError('해당 강습 과정이 등록되어 있지 않습니다. 관리자에게 문의하세요.');
      } else if (err.response?.status === 404) {
        setApiError('요청한 데이터를 찾을 수 없습니다.');
      } else {
        setApiError(`강습 일정을 가져오는 중 오류가 발생했습니다: ${err.message || '알 수 없는 오류'}`);
      }
      
      setDates([]);
    } finally {
      setLoadingDates(false);
    }
  };

  // 장소 선택 변경 시 해당 장소의 날짜 데이터 가져오기
  useEffect(() => {
    if (selectedCourse && selectedLocation) {
      fetchDates(selectedCourse, selectedLocation);
    }
  }, [selectedLocation, selectedCourse]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await api.get(`${process.env.REACT_APP_API_BASE_PATH}/class-products/${id}/`);
        const productData = res.data;

        // 제품 제목에서 강습 과정 추출
        const courseType = extractCourseFromTitle(productData.title);
        setSelectedCourse(courseType);
        
        // 강습 과정이 결정되었지만 장소는 아직 선택되지 않았으므로 전체 날짜 표시
        fetchDates(courseType);

        // main_image와 일반 images 배열만 합치기 (detail_images 제외)
        const mainImage = {
          image: productData.main_image.replace('/server', process.env.REACT_APP_API_URL),
          is_detail: false
        };
        
        // detail이 아닌 이미지만 필터링
        const normalImages = productData.images
          .filter(img => !img.is_detail)
          .map(img => ({
            ...img,
            image: img.image.replace('/server', process.env.REACT_APP_API_URL)
          }));
        
        // 메인 이미지를 첫 번째로 하고 일반 이미지들을 추가
        const combinedImages = [mainImage, ...normalImages];
        
        setProduct({
          ...productData,
          main_image: productData.main_image.replace('/server', process.env.REACT_APP_API_URL),
          images: productData.images.map(img => ({
            ...img,
            image: img.image.replace('/server', process.env.REACT_APP_API_URL)
          }))
        });
        setAllImages(combinedImages);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchProduct();
    fetchUserInfo();
    fetchLocations();
  }, [id]);

  // 사용자 정보 가져오기
  const fetchUserInfo = async () => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      try {
        const response = await api.get(`${process.env.REACT_APP_USER_API_BASE_PATH}/user-info/${storedUserId}`);
        setUserInfo({
          name: response.data.name,
          email: response.data.user_email,
          phone: response.data.hp
        });
      } catch (error) {
        console.error('사용자 정보 가져오기 실패:', error);
      }
    }
  };

  // 자동 슬라이드 설정
  useEffect(() => {
    if (allImages.length > 1) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % allImages.length);
      }, 3000); // 3초마다 이미지 변경

      setAutoSlideInterval(interval);

      return () => clearInterval(interval);
    }
  }, [allImages.length]);

  // 수동으로 이미지를 변경할 때 자동 슬라이드를 일시 중지했다가 재시작
  const handleManualSlide = (direction) => {
    if (autoSlideInterval) {
      clearInterval(autoSlideInterval);
    }

    if (direction === 'next') {
      nextImage();
    } else {
      prevImage();
    }

    // 5초 후에 자동 슬라이드 재시작
    const newInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % allImages.length);
    }, 3000);
    setAutoSlideInterval(newInterval);
  };

  const nextImage = () => {
    if (!allImages.length) return;
    setCurrentImageIndex((prev) => (prev + 1) % allImages.length);
  };

  const prevImage = () => {
    if (!allImages.length) return;
    setCurrentImageIndex((prev) => (prev - 1 + allImages.length) % allImages.length);
  };

  useEffect(() => {
    const handleScroll = () => {
      // 스크롤 위치에 따라 네비게이션 바 숨김/표시 설정
      setHideNavigation(window.scrollY > 150);
      
      // 스크롤 위치에 따라 활성 탭 변경
      const detailSection = document.getElementById('detail-section');
      const reviewSection = document.getElementById('review-section');
      const qnaSection = document.getElementById('qna-section');
      const returnSection = document.getElementById('return-section');
      
      const scrollPosition = window.scrollY + 200;
      
      if (returnSection && scrollPosition >= returnSection.offsetTop) {
        setActiveTab('return');
      } else if (qnaSection && scrollPosition >= qnaSection.offsetTop) {
        setActiveTab('qna');
      } else if (reviewSection && scrollPosition >= reviewSection.offsetTop) {
        setActiveTab('review');
      } else if (detailSection && scrollPosition >= detailSection.offsetTop) {
        setActiveTab('detail');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
    // TODO: 백엔드 연결 시 즐겨찾기 API 호출 추가
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const proceedToCheckout = async () => {
    // 강습권만 구매하는 경우 또는 모든 옵션이 선택된 경우에만 진행
    if (!voucherOnly && (!selectedLocation || !selectedDate)) {
      alert('강습 장소와 날짜를 모두 선택하거나, 강습권만 구매 옵션을 선택해주세요.');
      return;
    }

    if (!localStorage.getItem('userId')) {
      alert('로그인이 필요합니다.');
      navigate('/login');
      return;
    }

    // 결제 정보 준비
    const totalPrice = calculateTotalPrice();
    
    try {
      // API 엔드포인트 결정
      let apiEndpoint;
      if (voucherOnly) {
        // 클래스 제품 - 강습권만 구매
        apiEndpoint = `${process.env.REACT_APP_API_BASE_PATH}/pre-payment-check-course-only/`;
      } else {
        // 클래스 제품 - 스케줄 선택 구매
        apiEndpoint = `${process.env.REACT_APP_API_BASE_PATH}/pre-payment-check-class-schedule/`;
      }
      
      console.log('API 요청 경로:', apiEndpoint);
      
      // 스포츠 이름 추출
      const sportName = extractSportFromTitle(product.title);
      console.log('추출된 스포츠 이름:', sportName);
      console.log('선택된 강습 과정:', selectedCourse);
      console.log('선택된 강습 장소:', selectedLocation);
      console.log('선택된 강습 날짜:', selectedDate);
      
      // 요청 데이터 준비
      let requestData = {};
      
      if (voucherOnly) {
        // 강습권만 구매하는 경우 - 새로운 요청 구조
        requestData = {
          sport: product.sport || sportName,
          course: selectedCourse,
          selected_price: totalPrice,
          price_option: {
            participants: quantity
          }
        };
      } else {
        // 클래스 제품에서 스케줄 선택인 경우
        const selectedDateObj = dates.find(dateObj => dateObj.key === selectedDate);
        if (!selectedDateObj) {
          alert('선택한 강습 날짜 정보를 찾을 수 없습니다.');
          return;
        }
        
        console.log('결제를 위해 선택된 강습 날짜 정보:', selectedDateObj);
        
        requestData = {
          sport: product.sport || sportName,
          course: selectedCourse, 
          selected_price: totalPrice,
          course_type: getStandardizedCourseType(selectedCourse),
          sports_course_id: selectedDateObj.courseId,
          class_product_id: id,
          location: selectedLocation,
          quantity: quantity,
          selected_date: selectedDateObj.date,
          selected_time: selectedDateObj.time
        };
      }

      console.log('API endpoint:', apiEndpoint);
      console.log('Request data:', requestData);

      // API 호출
      console.log('클래스 결제 API 요청 시작:', apiEndpoint);
      console.log('클래스 결제 요청 데이터:', JSON.stringify(requestData, null, 2));
      
      const response = await api.post(apiEndpoint, requestData);
      console.log('API 응답:', response.data);
      console.log('API 응답 구조:', JSON.stringify(response.data, null, 2));

      // API 응답 처리
      if (!response.data.success || response.data.error) {
        console.error('API 오류 응답:', response.data);
        alert(response.data.error || response.data.message || '결제 준비에 실패했습니다.');
        return;
      }
      
      // 응답에서 필요한 정보 추출
      const paymentData = response.data;
      
      // moid 값 결정 (voucherOnly 여부와 관계없이 API 응답 사용)
      let moid;
      if (paymentData.payment_info && paymentData.payment_info.moid) {
        moid = paymentData.payment_info.moid; // API 응답에서 받은 moid를 사용
      } else {
        // 폴백 로직 (만약을 위해 남겨둠 - API가 moid를 안 줄 경우)
        console.warn("API 응답에서 moid를 찾을 수 없습니다. 기본값 사용");
        moid = voucherOnly 
          ? `course_only_${id}_${localStorage.getItem('userId')}` // 기본값 형식도 base_course_id를 사용하도록 수정 필요 가능성 있음
          : `class_${id}`;
      }
      
      console.log('결제에 사용할 moid:', moid); // 올바른 moid가 출력되는지 확인

      // 결제 정보 구성
      let paymentInfo = {
        GoodsName: product.title,
        Amt: totalPrice.toString(),
        ReturnURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}/payment-result/`,
        BuyerName: userInfo.name,
        BuyerEmail: userInfo.email,
        BuyerTel: userInfo.phone,
        Moid: moid
      };

      console.log('결제 모듈 전송 데이터:', paymentInfo);

      // 결제 초기화 및 진행
      if (typeof window.initializePayment === 'function') {
        window.initializePayment(paymentInfo);
      } else {
        console.error('initializePayment function is not defined');
        alert('결제 모듈 초기화에 실패했습니다. 다시 시도해주세요.');
      }
    } catch (error) {
      console.error('결제 처리 중 오류 발생:', error);
      
      // 오류 응답 상세 정보 출력
      if (error.response) {
        console.error('에러 상태:', error.response.status);
        console.error('에러 응답:', error.response.data);
        
        // BaseSportsCourse 오류 특별 처리
        if (error.response.data?.code === '1006' && 
            error.response.data?.message?.includes('BaseSportsCourse matching query does not exist')) {
          alert('선택한 강습 과정 정보를 찾을 수 없습니다. 강습권만 구매하시거나 다른 강습 일정을 선택해주세요.');
          return;
        }
      }
      
      alert('결제 처리 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  // 알리페이 결제 함수도 동일하게 수정
  const handleAlipayPayment = async () => {
    // 강습권만 구매하는 경우 또는 모든 옵션이 선택된 경우에만 진행
    if (!voucherOnly && (!selectedLocation || !selectedDate)) {
      alert('강습 장소와 날짜를 모두 선택하거나, 강습권만 구매 옵션을 선택해주세요.');
      return;
    }

    if (!localStorage.getItem('userId')) {
      alert('로그인이 필요합니다.');
      navigate('/login');
      return;
    }

    // 결제 정보 준비
    const totalPrice = calculateTotalPrice();
    
    try {
      // API 엔드포인트 결정
      let apiEndpoint;
      if (voucherOnly) {
        // 클래스 제품 - 강습권만 구매
        apiEndpoint = `${process.env.REACT_APP_API_BASE_PATH}/pre-payment-check-course-only/`;
      } else {
        // 클래스 제품 - 스케줄 선택 구매
        apiEndpoint = `${process.env.REACT_APP_API_BASE_PATH}/pre-payment-check-class-schedule/`;
      }
      
      console.log('API 요청 경로:', apiEndpoint);
      
      // 스포츠 이름 추출
      const sportName = extractSportFromTitle(product.title);
      console.log('추출된 스포츠 이름:', sportName);
      console.log('선택된 강습 과정:', selectedCourse);
      console.log('선택된 강습 장소:', selectedLocation);
      console.log('선택된 강습 날짜:', selectedDate);
      
      // 요청 데이터 준비
      let requestData = {};
      
      if (voucherOnly) {
        // 강습권만 구매하는 경우 - 새로운 요청 구조
        requestData = {
          sport: product.sport || sportName,
          course: selectedCourse,
          selected_price: totalPrice,
          price_option: {
            participants: quantity
          }
        };
      } else {
        // 클래스 제품에서 스케줄 선택인 경우
        const selectedDateObj = dates.find(dateObj => dateObj.key === selectedDate);
        if (!selectedDateObj) {
          alert('선택한 강습 날짜 정보를 찾을 수 없습니다.');
          return;
        }
        
        console.log('결제를 위해 선택된 강습 날짜 정보:', selectedDateObj);
        
        requestData = {
          sport: product.sport || sportName,
          course: selectedCourse, 
          selected_price: totalPrice,
          course_type: getStandardizedCourseType(selectedCourse),
          sports_course_id: selectedDateObj.courseId,
          class_product_id: id,
          location: selectedLocation,
          quantity: quantity,
          selected_date: selectedDateObj.date,
          selected_time: selectedDateObj.time
        };
      }

      console.log('API endpoint:', apiEndpoint);
      console.log('Request data:', requestData);

      // API 호출
      console.log('알리페이 결제 API 요청 시작:', apiEndpoint);
      console.log('알리페이 결제 요청 데이터:', JSON.stringify(requestData, null, 2));
      
      const response = await api.post(apiEndpoint, requestData);
      console.log('API 응답:', response.data);
      console.log('API 응답 구조:', JSON.stringify(response.data, null, 2));

      // API 응답 처리
      if (!response.data.success || response.data.error) {
        console.error('API 오류 응답:', response.data);
        alert(response.data.error || response.data.message || '결제 준비에 실패했습니다.');
        return;
      }
      
      // 응답에서 필요한 정보 추출
      const paymentData = response.data;
      
      // moid 값 결정 (voucherOnly 여부와 관계없이 API 응답 사용)
      let moid;
      if (paymentData.payment_info && paymentData.payment_info.moid) {
        moid = paymentData.payment_info.moid; // API 응답에서 받은 moid를 사용
      } else {
        // 폴백 로직 (만약을 위해 남겨둠 - API가 moid를 안 줄 경우)
        console.warn("API 응답에서 moid를 찾을 수 없습니다. 기본값 사용");
        moid = voucherOnly 
          ? `course_only_${id}_${localStorage.getItem('userId')}` // 기본값 형식도 base_course_id를 사용하도록 수정 필요 가능성 있음
          : `class_${id}`;
      }
      
      console.log('결제에 사용할 moid:', moid); // 올바른 moid가 출력되는지 확인

      // 결제 정보 구성
      let paymentInfo = {
        GoodsName: product.title,
        Amt: totalPrice.toString(),
        ReturnURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}/payment-result-ali/`,
        RetryURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}/payment-retry-ali/`,
        BuyerName: userInfo.name,
        BuyerEmail: userInfo.email,
        BuyerTel: userInfo.phone,
        Moid: moid
      };

      console.log('알리페이 결제 모듈 전송 데이터:', paymentInfo);

      // 알리페이 결제 초기화 및 진행
      if (typeof window.initializeAlipay === 'function') {
        window.initializeAlipay(paymentInfo);
      } else {
        console.error('initializeAlipay function is not defined');
        alert('알리페이 결제 모듈 초기화에 실패했습니다. 다시 시도해주세요.');
      }
    } catch (error) {
      console.error('알리페이 결제 처리 중 오류 발생:', error);
      
      // 오류 응답 상세 정보 출력
      if (error.response) {
        console.error('에러 상태:', error.response.status);
        console.error('에러 응답:', error.response.data);
        
        // BaseSportsCourse 오류 특별 처리
        if (error.response.data?.code === '1006' && 
            error.response.data?.message?.includes('BaseSportsCourse matching query does not exist')) {
          alert('선택한 강습 과정 정보를 찾을 수 없습니다. 강습권만 구매하시거나 다른 강습 일정을 선택해주세요.');
          return;
        }
      }
      
      alert('알리페이 결제 처리 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  const goToImage = (index) => {
    setCurrentImageIndex(index);
  };

  // 총 주문 금액 계산
  const calculateTotalPrice = () => {
    if (!product) return 0;
    return product.discount_price * quantity;
  };

  // 탭 클릭 시 해당 섹션으로 스크롤
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -120; // 오프셋 값을 -80에서 -120으로 더 크게 조정
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      setActiveTab(sectionId.split('-')[0]);
    }
  };

  // 날짜 포맷 변환 함수
  const formatDate = (dateTimeString) => {
    // 날짜와 시간이 T로 구분된 형식에서 분리
    const [dateStr, timeStr] = dateTimeString.split('T');
    
    // 날짜 포맷
    const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'short' };
    const formattedDate = new Date(dateStr).toLocaleDateString('ko-KR', options);
    
    // 시간 포맷 (시간이 있는 경우에만)
    if (timeStr && timeStr !== '00:00') {
      // 시간이 HH:MM 형식인 경우
      const [hours, minutes] = timeStr.split(':');
      return `${formattedDate} ${hours}시${minutes !== '00' ? ` ${minutes}분` : ''}`;
    }
    
    return formattedDate;
  };

  // 페이지 로딩 후 URL 파라미터 검사
  useEffect(() => {
    // URL 파라미터에서 결제 결과 정보 확인
    const urlParams = new URLSearchParams(window.location.search);
    const result = urlParams.get('result');
    const message = urlParams.get('message');
    
    if (result === 'fail') {
      // 결제 실패 메시지 표시
      let errorMessage = '결제 처리 중 오류가 발생했습니다.';
      
      if (message === 'class_payment_error') {
        errorMessage = '클래스 결제 처리 중 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.';
      } else if (message === 'class_order_not_found') {
        errorMessage = '클래스 주문 정보를 찾을 수 없습니다. 다시 시도해 주세요.';
      }
      
      alert(errorMessage);
      
      // 홈 페이지로 리다이렉트
      navigate('/');
    }
  }, [navigate]);

  // 상품 옵션 구성 전에 코스 타입 처리
  useEffect(() => {
    if (product) {
      // 제품 제목에서 강습 과정 추출
      const courseType = extractCourseFromTitle(product.title);
      setSelectedCourse(courseType);
      
      console.log('제품 제목에서 추출한 강습 과정:', courseType);
      console.log('표준화된 코스 타입:', getStandardizedCourseType(courseType));
    }
  }, [product]);

  if (loading) return <div className="loading-container">로딩중...</div>;

  if (!product) return <div>상품 정보를 찾을 수 없습니다.</div>;

  // 일반 이미지와 상세 이미지 분리
  const normalImages = product.images.filter(img => !img.is_detail);
  const detailImages = product.images.filter(img => img.is_detail);

  return (
    <div className="product-detail-page">
      <div className="product-header">
        <div></div>
        <button className="back-button" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} className="icon" />
        </button>
      </div>

      <div className="product-image-slider">
        <div 
          className="image-container"
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <button className="slider-arrow prev" onClick={() => handleManualSlide('prev')}>
            <FontAwesomeIcon icon={faChevronLeft} className="icon" />
          </button>
          <img 
            src={allImages[currentImageIndex]?.image} 
            alt={product?.title}
            draggable="false"
          />
          <button className="slider-arrow next" onClick={() => handleManualSlide('next')}>
            <FontAwesomeIcon icon={faChevronRight} className="icon" />
          </button>
          <div className="image-pagination">
            {allImages.map((_, index) => (
              <span 
                key={index} 
                className={index === currentImageIndex ? 'active' : ''}
                onClick={() => {
                  setCurrentImageIndex(index);
                  if (autoSlideInterval) {
                    clearInterval(autoSlideInterval);
                    const newInterval = setInterval(() => {
                      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % allImages.length);
                    }, 3000);
                    setAutoSlideInterval(newInterval);
                  }
                }}
              ></span>
            ))}
          </div>
        </div>
      </div>

      <div className="product-info-section">
        <div className="product-header-info">
          <div className="product-title-section">
            <div className="product-brand">{product.brand}</div>
            <h1 className="product-name">{product.title}</h1>
          </div>
          <div className="product-action-buttons">
            <button 
              className={`favorite-button ${isFavorite ? 'active' : ''}`}
              onClick={toggleFavorite}
            >
              <FontAwesomeIcon 
                icon={isFavorite ? faHeartSolid : faHeartRegular} 
                className="icon" 
              />
            </button>
          </div>
        </div>

        <div className="product-price">
          <div className="discount-info">
            <span className="discount-rate">{product.discount_rate}%</span>
            <span className="original-price">{product.original_price.toLocaleString()}원</span>
          </div>
          <div className="current-price">{product.discount_price.toLocaleString()}원</div>
        </div>
        
        <div className="benefits-info">
          <div className="benefit-item">
            <span className="benefit-label">강습 인증</span>
            <span className="benefit-value">자격증 발급</span>
          </div>
        </div>
      </div>
      
      <div className="product-options-section">
        <div className="voucher-only-option">
          <label className="voucher-checkbox-label">
            <input 
              type="checkbox" 
              checked={voucherOnly}
              onChange={() => setVoucherOnly(!voucherOnly)}
              className="voucher-checkbox"
            />
            <span className="voucher-label-text">강습권만 구매</span>
            <div className="voucher-tooltip">
              <span className="voucher-tooltip-icon">?</span>
              <div className="voucher-tooltip-text">
                강습권만 구매하면 나중에 예약 페이지에서 원하는 날짜와 장소를 선택할 수 있습니다.
              </div>
            </div>
          </label>
        </div>

        {!voucherOnly && (
          <div className="option-selector">
            <div className="option-header">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="option-icon" />
              <span className="option-title">강습 장소</span>
            </div>
            <select 
              className="option-select"
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
            >
              <option value="">강습 장소 선택</option>
              {locations.map((location, index) => (
                <option key={index} value={location}>{location}</option>
              ))}
            </select>
          </div>
        )}
        
        <div className="option-selector">
          <div className="option-header">
            <FontAwesomeIcon icon={faUsers} className="option-icon" />
            <span className="option-title">강습 과정</span>
          </div>
          {/* 강습 과정은 제품 제목에서 추출하여 고정값으로 사용 */}
          <select 
            className="option-select"
            value={selectedCourse}
            disabled
          >
            <option value={selectedCourse}>{selectedCourse}</option>
          </select>
        </div>
        
        {!voucherOnly && (
          <div className="option-selector">
            <div className="option-header">
              <FontAwesomeIcon icon={faCalendarAlt} className="option-icon" />
              <span className="option-title">강습 날짜</span>
            </div>
            {loadingDates ? (
              <div className="loading-dates">강습 일정을 불러오는 중...</div>
            ) : apiError ? (
              <div className="error-message">{apiError}</div>
            ) : (
              <select 
                className="option-select"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                disabled={dates.length === 0}
              >
                <option value="">강습 날짜 및 시간 선택</option>
                {dates.map((dateObj, index) => (
                  <option key={index} value={dateObj.key}>
                    {formatDate(dateObj.key)} {dateObj.locationName ? `- ${dateObj.locationName}` : ''}
                  </option>
                ))}
              </select>
            )}
          </div>
        )}
        
        <div className="quantity-selector">
          <div className="option-header">
            <span className="option-title">인원 수</span>
          </div>
          <div className="quantity-control">
            <button 
              className="quantity-button" 
              onClick={decreaseQuantity} 
              disabled={quantity <= 1}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <input 
              type="number" 
              className="quantity-input" 
              value={quantity} 
              readOnly 
            />
            <button className="quantity-button" onClick={increaseQuantity}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
        
        {(voucherOnly || (selectedLocation && selectedDate)) && (
          <div className="selected-options">
            <h3 className="selected-options-title">
              {voucherOnly ? "강습권 구매 정보" : "선택한 강습 정보"}
            </h3>
            <div className="selected-option-item">
              <div className="selected-option-info">
                <div className="option-info-row">
                  <FontAwesomeIcon icon={faUsers} className="option-info-icon" />
                  <span className="option-info-label">강습 과정:</span>
                  <span className="option-info-value">{selectedCourse}</span>
                </div>
                
                {!voucherOnly && (
                  <>
                    <div className="option-info-row">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="option-info-icon" />
                      <span className="option-info-label">강습 장소:</span>
                      <span className="option-info-value">{selectedLocation}</span>
                    </div>
                    <div className="option-info-row">
                      <FontAwesomeIcon icon={faCalendarAlt} className="option-info-icon" />
                      <span className="option-info-label">강습 일정:</span>
                      <span className="option-info-value">{formatDate(selectedDate)}</span>
                    </div>
                  </>
                )}
                
                {voucherOnly && (
                  <div className="option-info-row">
                    <FontAwesomeIcon icon={faCalendarAlt} className="option-info-icon" />
                    <span className="option-info-label">예약 방법:</span>
                    <span className="option-info-value">예약 페이지에서 예약 가능</span>
                  </div>
                )}
                
                <div className="option-info-row">
                  <FontAwesomeIcon icon={faUsers} className="option-info-icon" />
                  <span className="option-info-label">신청 인원:</span>
                  <span className="option-info-value">{quantity}명</span>
                </div>
              </div>
              <div className="selected-option-price-container">
                <span className="selected-option-price-label">합계</span>
                <span className="selected-option-price">{(product.discount_price * quantity).toLocaleString()}원</span>
              </div>
            </div>
          </div>
        )}
        
        <div className="total-price-section">
          <span className="total-price-label">총 주문금액</span>
          <span className="total-price-value">{calculateTotalPrice().toLocaleString()}원</span>
        </div>
      </div>

      {/* 상품 페이지 탭 메뉴 */}
      <div className="product-tabs">
        <div className="tabs-container">
          <button 
            className={`tab-button ${activeTab === 'detail' ? 'active' : ''}`}
            onClick={() => scrollToSection('detail-section')}
          >
            상세정보
          </button>
          <button 
            className={`tab-button ${activeTab === 'review' ? 'active' : ''}`}
            onClick={() => scrollToSection('review-section')}
          >
            리뷰
          </button>
          <button 
            className={`tab-button ${activeTab === 'qna' ? 'active' : ''}`}
            onClick={() => scrollToSection('qna-section')}
          >
            Q&A
          </button>
          <button 
            className={`tab-button ${activeTab === 'return' ? 'active' : ''}`}
            onClick={() => scrollToSection('return-section')}
          >
            반품/교환
          </button>
        </div>
      </div>

      <div className="content-section">
        {/* 상세정보 섹션 */}
        <div id="detail-section" className="content-block">
          <h2 className="section-title">상세정보</h2>
          <div className={`detail-images ${showFullDetail ? 'expanded' : ''}`}>
            {detailImages.slice(0, showFullDetail ? undefined : 1).map((image, index) => (
              <div key={index} className="detail-image-wrapper">
                <img src={image.image} alt={`상품 상세 ${index + 1}`} />
              </div>
            ))}
            {detailImages.length > 1 && (
              <button 
                className="toggle-detail-button"
                onClick={() => setShowFullDetail(!showFullDetail)}
              >
                {showFullDetail ? (
                  <>
                    <span>상세정보 접기</span>
                    <FontAwesomeIcon icon={faAngleUp} className="icon" />
                  </>
                ) : (
                  <>
                    <span>상세정보 더보기</span>
                    <FontAwesomeIcon icon={faAngleDown} className="icon" />
                  </>
                )}
              </button>
            )}
          </div>
        </div>

        {/* 리뷰 섹션 */}
        <div id="review-section" className="content-block">
          <div className="section-header">
            <h2 className="section-title">리뷰 317개</h2>
            <button className="write-review-btn">리뷰 작성</button>
          </div>
          
          <div className="review-summary">
            <div className="review-rating-average">
              <span className="star-icon">★</span>
              <span className="rating-number">4.9</span>
            </div>
            <div className="review-images-preview">
              {/* 리뷰 이미지 미리보기 (비어있는 회색 박스로 표시) */}
              <div className="review-image-box"></div>
              <div className="review-image-box"></div>
              <div className="review-image-box"></div>
              <div className="review-image-box"></div>
            </div>
          </div>
          
          {reviews.length > 0 ? (
            <div className="reviews-list">
              {reviews.map(review => (
                <div key={review.id} className="review-item">
                  <div className="review-stars">
                    {[...Array(5)].map((_, i) => (
                      <span key={i} className="star-icon">★</span>
                    ))}
                  </div>
                  <div className="review-header">
                    <span className="review-author">{review.author}</span>
                    <span className="review-date">25.02.11</span>
                  </div>
                  <p className="review-content">{review.content}</p>
                  {/* 리뷰 이미지 추가 (회색 박스로 표시) */}
                  <div className="review-image-container">
                    <div className="review-image-box large"></div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="no-reviews">등록된 리뷰가 없습니다.</p>
          )}
          
          <button className="view-more-button">
            리뷰 더보기
          </button>
        </div>
        
        {/* Q&A 섹션 */}
        <div id="qna-section" className="content-block">
          <div className="section-header">
            <h2 className="section-title">Q&A</h2>
            <button className="write-inquiry-btn">문의하기</button>
          </div>
          <p className="no-inquiries">등록된 문의가 없습니다.</p>
        </div>
        
        {/* 반품/교환 섹션 */}
        <div id="return-section" className="content-block">
          <h2 className="section-title">반품/교환정보</h2>
          <div className="return-exchange-info">
            <h3 className="info-subtitle">{returnPolicy.title}</h3>
            <p>{returnPolicy.description}</p>
            
            <div className="return-exchange-table">
              <div className="table-row">
                <div className="table-cell header">반품/교환 사유에 따른 요청 가능 기간</div>
                <div className="table-cell">
                  {returnPolicy.return_period.map((period, index) => (
                    <p key={index}>{period}</p>
                  ))}
                </div>
              </div>
              
              <div className="table-row">
                <div className="table-cell header">반품/교환 불가능 사유</div>
                <div className="table-cell">
                  <ol>
                    {returnPolicy.return_reasons.map((reason, index) => (
                      <li key={index}>{reason}</li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
            
            <div className="exchange-info-table">
              <div className="table-row">
                <div className="table-cell header">판매자정보</div>
                <div className="table-cell">
                  <p>상호명: {returnPolicy.seller_info.company_name}</p>
                  <p>사업자구분: {returnPolicy.seller_info.business_type}</p>
                  <p>대표자: {returnPolicy.seller_info.representative}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-actions nav-margin-bottom">
        <div className="action-buttons-container">
          <button className="checkout-button" onClick={proceedToCheckout}>
            <span className="checkout-text">구매하기</span>
          </button>
          <button className="alipay-button" onClick={handleAlipayPayment}>
            <i className="fab fa-alipay mr-2"></i>알리페이 결제
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailPage;
