import React, { useState, useEffect, useRef } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './common.css';
import './SportsInput.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import api from '../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const SportsInput = () => {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean']
      ],
      clipboard: {
        matchVisual: false,
      }
    },
    formats: ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link', 'image'],
    theme: 'snow',
  });

  // 상태 관리
  const [formData, setFormData] = useState({
    selectedDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    selectedSport: '',
    selectedLocation: '',
    isTheory: false,
    selectedCourse: '',
    maxLessonParticipants: 1,
    maxPracticeParticipants: 1,
    courseLevel: 0,
    selectedDays: 1
  });

  // UI 상태
  const [uiState, setUiState] = useState({
    isDatePickerOpen: false,
    isTimePickerOpen: { start: false, end: false },
    showMessage: false,
    messageContent: '',
    showTheoryOption: false,
    hasTheory: false,
    maxDays: 0
  });

  // 데이터 상태
  const [dataState, setDataState] = useState({
    sports: [],
    baseCourses: [],
    sportsCourses: {},
    locations: []
  });

  const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const courseId = searchParams.get('courseId');
  const sportParam = searchParams.get('sport');
  const [userLevel, setUserLevel] = useState('');

  // ref
  const startTimePickerRef = useRef(null);
  const endTimePickerRef = useRef(null);
  const datePickerRef = useRef(null);

  // 초기 데이터 로드
  useEffect(() => {
    // URL 파라미터 디버깅
    console.log("URL sport parameter:", sportParam);
    
    const fetchBaseCourses = async () => {
      try {
        const response = await api.get(`${API_BASE_PATH}/base-sports-courses/`);
        const data = response.data;

        const coursesMap = {};
        const uniqueSports = [];

        for (let i = 0; i < data.base_courses.length; i++) {
          const item = data.base_courses[i];
          const sportName = item.sport.name;
          const courseType = item.course_type;

          if (!uniqueSports.includes(sportName)) {
            uniqueSports.push(sportName);
          }

          if (!coursesMap[sportName]) {
            coursesMap[sportName] = [];
          }

          if (!coursesMap[sportName].some(course => course.id === courseType.id)) {
            coursesMap[sportName].push({
              id: courseType.id,
              name: courseType.name
            });
          }
        }

        setDataState({
          ...dataState,
          sports: uniqueSports,
          sportsCourses: coursesMap,
          baseCourses: data.base_courses,
          locations: data.locations.map(loc => loc.name)
        });

        // URL에서 받은, 또는 첫 번째 스포츠를 선택
        let selectedSportName = '';
        
        // 스포츠 목록 디버깅
        console.log("Available sports:", uniqueSports);
        
        // 1. courseId가 있으면 수정 모드이므로 선택하지 않음 (fetchCourseData에서 처리)
        if (!courseId) {
          // 2. URL에 스포츠 파라미터가 있으면 해당 스포츠를 선택
          // 대소문자 구분 없이 스포츠 이름 비교
          if (sportParam) {
            const sportMatch = uniqueSports.find(
              sport => sport.toLowerCase() === sportParam.toLowerCase()
            );
            
            if (sportMatch) {
              selectedSportName = sportMatch;
              console.log("Selected sport from URL:", selectedSportName);
            } else {
              // URL에 있는 스포츠가 목록에 없으면 첫 번째 스포츠 선택
              selectedSportName = uniqueSports.length > 0 ? uniqueSports[0] : '';
              console.log("Sport from URL not found in list, using default:", selectedSportName);
            }
          } 
          // 3. 그렇지 않으면 첫 번째 스포츠 선택
          else if (uniqueSports.length > 0) {
            selectedSportName = uniqueSports[0];
            console.log("No sport in URL, using first sport:", selectedSportName);
          }
          
          setFormData(prev => ({
            ...prev,
            selectedSport: selectedSportName,
            selectedCourse: '',
            selectedLocation: ''
          }));
        }

        return true;
      } catch (error) {
        console.error('기본 데이터 가져오기 실패:', error);
        return false;
      }
    };

    const fetchCourseData = async () => {
      if (courseId && quill) {
        try {
          const response = await api.get(`${API_BASE_PATH}/get-sports-schedule/${courseId}/`);
          const courseData = response.data;
          
          setFormData({
            ...formData,
            selectedSport: courseData.sport,
            selectedLocation: courseData.location,
            selectedCourse: courseData.course,
            selectedDate: new Date(courseData.date),
            startTime: new Date(`2000-01-01T${courseData.startTime}`),
            endTime: new Date(`2000-01-01T${courseData.endTime}`),
            maxLessonParticipants: courseData.maxLessonParticipants,
            maxPracticeParticipants: courseData.maxPracticeParticipants,
            courseLevel: courseData.allowed_user_levels,
            selectedDays: courseData.day
          });
          
          setUiState({
            ...uiState,
            maxDays: courseData.max_days,
            showTheoryOption: courseData.has_theory,
            hasTheory: courseData.has_theory
          });

          if (courseData.has_theory) {
            setFormData(prev => ({
              ...prev,
              isTheory: courseData.is_theory
            }));
          }

          quill.root.innerHTML = courseData.content;
        } catch (error) {
          console.error('코스 데이터 가져오기 실패:', error);
        }
      }
    };

    const initializeData = async () => {
      const baseDataLoaded = await fetchBaseCourses();
      if (baseDataLoaded) {
        await fetchCourseData();
      }
    };

    initializeData();
    
    // 사용자 레벨 로드
    setUserLevel(localStorage.getItem("level") || '');
  }, [courseId, quill]);

  // 이미지 업로드 핸들러
  useEffect(() => {
    if (quill) {
      quill.getModule('toolbar').addHandler('image', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
          const file = input.files[0];
          const formData = new FormData();
          formData.append('file', file);

          try {
            const response = await api.post(`${API_BASE_PATH}/upload-image/`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            const url = response.data.image_url;
            const range = quill.getSelection();
            quill.insertEmbed(range.index, 'image', url);
            quill.setSelection(range.index + 1);
          } catch (error) {
            console.error('Image upload failed:', error);
          }
        };
      });
    }
  }, [quill]);

  // 클릭 외부 감지
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (startTimePickerRef.current && !startTimePickerRef.current.contains(event.target)) {
        setUiState(prev => ({
          ...prev,
          isTimePickerOpen: { ...prev.isTimePickerOpen, start: false }
        }));
      }
      if (endTimePickerRef.current && !endTimePickerRef.current.contains(event.target)) {
        setUiState(prev => ({
          ...prev,
          isTimePickerOpen: { ...prev.isTimePickerOpen, end: false }
        }));
      }
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setUiState(prev => ({
          ...prev,
          isDatePickerOpen: false
        }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // 핸들러 함수들
  const handleSportChange = (e) => {
    setFormData({
      ...formData,
      selectedSport: e.target.value,
      selectedCourse: ''  // 스포츠가 변경되면 코스 초기화
    });
  };

  const handleCourseChange = (e) => {
    const selectedCourseName = e.target.value;
    setFormData({
      ...formData,
      selectedCourse: selectedCourseName
    });

    const selectedCourseData = dataState.baseCourses.find(course => 
      course.sport.name === formData.selectedSport && course.course_type.name === selectedCourseName
    );

    if (selectedCourseData) {
      setUiState({
        ...uiState,
        maxDays: selectedCourseData.max_days,
        showTheoryOption: selectedCourseData.has_theory,
        hasTheory: selectedCourseData.has_theory
      });

      if (selectedCourseData.max_days > 1) {
        setFormData(prev => ({
          ...prev,
          selectedDays: 1  // 기본값으로 1일 설정
        }));
      }
    }
  };

  const handleSave = async () => {
    if (quill) {
      const content = quill.root.innerHTML;
      
      // 날짜를 YYYY-MM-DD 형식으로 변환
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      // 시간을 HH:MM 형식으로 변환
      const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
      };

      // 유효성 검사
      if (!formData.selectedSport) {
        setUiState({
          ...uiState,
          showMessage: true,
          messageContent: '스포츠 종목을 선택해주세요.'
        });
        return;
      }

      if (!formData.selectedCourse) {
        setUiState({
          ...uiState,
          showMessage: true,
          messageContent: '과정을 선택해주세요.'
        });
        return;
      }

      if (!formData.selectedLocation) {
        setUiState({
          ...uiState,
          showMessage: true,
          messageContent: '장소를 선택해주세요.'
        });
        return;
      }

      let lastSelectedDays = 0;
      if (uiState.maxDays > 1) {
        lastSelectedDays = formData.selectedDays || 1;
      } else {
        lastSelectedDays = 0;
      }

      const data = {
        sport: formData.selectedSport,
        location: formData.selectedLocation,
        course: formData.selectedCourse,
        date: formatDate(formData.selectedDate),
        startTime: formatTime(formData.startTime),
        endTime: formatTime(formData.endTime),
        maxLessonParticipants: formData.maxLessonParticipants,
        maxPracticeParticipants: formData.maxPracticeParticipants,
        content,
        day: lastSelectedDays,
        is_theory: formData.isTheory,
        allowed_user_levels: formData.courseLevel,
      };

      try {
        let response;
        if (courseId) {
          // 기존 코스 수정
          response = await api.put(`${API_BASE_PATH}/update-sports-schedule/${courseId}/`, data);
          setUiState({
            ...uiState,
            showMessage: true,
            messageContent: '코스가 성공적으로 수정되었습니다.'
          });
        } else {
          // 새 코스 생성
          response = await api.post(`${API_BASE_PATH}/save-sports-schedule/`, data);
          setUiState({
            ...uiState,
            showMessage: true,
            messageContent: '새로운 코스가 성공적으로 생성되었습니다.'
          });
        }
      } catch (error) {
        console.error('코스 저장 중 오류 발생:', error);
        setUiState({
          ...uiState,
          showMessage: true,
          messageContent: '코스 저장 중 오류가 발생했습니다.'
        });
      }
    }
  };

  const handleApprove = async () => {
    if (quill) {
      const content = quill.root.innerHTML;
      
      // 날짜를 YYYY-MM-DD 형식으로 변환
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      // 시간을 HH:MM 형식으로 변환
      const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
      };

      const data = {
        sport: formData.selectedSport,
        location: formData.selectedLocation,
        course: formData.selectedCourse,
        date: formatDate(formData.selectedDate),
        startTime: formatTime(formData.startTime),
        endTime: formatTime(formData.endTime),
        maxLessonParticipants: formData.maxLessonParticipants,
        maxPracticeParticipants: formData.maxPracticeParticipants,
        content,
        allowed_user_levels: formData.courseLevel,
        status: 'approved'
      };

      try {
        if (courseId) {
          await api.put(`${API_BASE_PATH}/approve-sports-schedule/${courseId}/`, data);
          setUiState({
            ...uiState,
            showMessage: true,
            messageContent: '성공적으로 승인되었습니다.'
          });
        }
      } catch (error) {
        console.error('코스 승인 중 오류 발생:', error);
        setUiState({
          ...uiState,
          showMessage: true,
          messageContent: '코스 승인 중 오류가 발생했습니다.'
        });
      }
    }
  };

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const toggleDatePicker = () => {
    setUiState({
      ...uiState,
      isDatePickerOpen: !uiState.isDatePickerOpen
    });
  };

  const toggleTimePicker = (type) => {
    setUiState({
      ...uiState,
      isTimePickerOpen: {
        ...uiState.isTimePickerOpen,
        [type]: !uiState.isTimePickerOpen[type]
      }
    });
  };

  const closeMessage = () => {
    setUiState({
      ...uiState,
      showMessage: false
    });
    navigate('/sports-learning');
  };

  return (
    <div className="input-container">
      <h2 className="input-section-title">스포츠 학습 입력</h2>

      <div className="input-form-group">
        <div className="input-row">
          <div className="input-item">
            <label>스포츠<br/>종목</label>
            <select 
              className="sportsinput-form-control"
              value={formData.selectedSport}
              onChange={handleSportChange}
            >
              <option value="">스포츠 선택</option>
              {dataState.sports.map((sportName, index) => (
                <option key={index} value={sportName}>{sportName}</option>
              ))}
            </select>
          </div>
          <div className="input-item">
            <label>과정</label>
            <select 
              className="sportsinput-form-control"
              value={formData.selectedCourse}
              onChange={handleCourseChange}
              disabled={!formData.selectedSport}
            >
              <option value="">과정 선택</option>
              {dataState.sportsCourses[formData.selectedSport]?.map((course, index) => (
                <option key={index} value={course.name}>{course.name}</option>
              ))}
            </select>
          </div>
        </div>
        {uiState.maxDays >= 2 && (
        <div className="input-row">
          <div className="input-item">
            <label>일차</label>
            <select
              className="sportsinput-form-control"
              value={formData.selectedDays}
              onChange={(e) => handleInputChange('selectedDays', Number(e.target.value))}
            >
              {[...Array(uiState.maxDays)].map((_, i) => (
                <option key={i} value={i + 1}>Days {i + 1}</option>
              ))}
            </select>
          </div>
          {uiState.showTheoryOption && (
          <div className="sportsinput-toggle-item">
            <label className="sportsinput-toggle-label">이론수업</label>
            <div className="sportsinput-toggle-container">
              <label className="sportsinput-toggle-switch">
                <input
                  type="checkbox"
                  checked={formData.isTheory}
                  onChange={(e) => handleInputChange('isTheory', e.target.checked)}
                />
                <span className="sportsinput-toggle-slider"></span>
              </label>
            </div>
          </div>
          )}
        </div>
        )}
        <div className="input-row">
        <div className="input-item">
            <label>장소</label>
            <select 
              className="sportsinput-form-control"
              value={formData.selectedLocation}
              onChange={(e) => handleInputChange('selectedLocation', e.target.value)}
            >
              <option value="">장소 선택</option>
              {dataState.locations.map((location, index) => (
                <option key={index} value={location}>{location}</option>
              ))}
            </select>
          </div>
          <div className="input-item">
            <label>일정</label>
            <div className="sportsinput-form-control date-picker" onClick={toggleDatePicker}>
              {formData.selectedDate ? formData.selectedDate.toLocaleDateString() : "날짜를 선택하세요"}
            </div>
            {uiState.isDatePickerOpen && (
              <div className="sportsinput-custom-modal" ref={datePickerRef}>
                <DatePicker
                  selected={formData.selectedDate}
                  onChange={(date) => {
                    handleInputChange('selectedDate', date);
                    setUiState({...uiState, isDatePickerOpen: false});
                  }}
                  inline
                />
              </div>
            )}
          </div>
        </div>

        <div className="input-row">
          <div className="input-item">
            <label>시작 시간</label>
            <div className="sportsinput-form-control date-picker" onClick={() => toggleTimePicker('start')}>
              {formData.startTime ? formData.startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "시작 시간을 선택하세요"}
            </div>
            {uiState.isTimePickerOpen.start && (
              <div className="sportsinput-custom-modal" ref={startTimePickerRef}>
                <DatePicker
                  selected={formData.startTime}
                  onChange={(date) => {
                    handleInputChange('startTime', date);
                    setUiState({...uiState, isTimePickerOpen: {...uiState.isTimePickerOpen, start: false}});
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="시간"
                  dateFormat="hh:mm"
                  inline
                />
              </div>
            )}
          </div>
          <div className="input-item">
            <label>종료 시간</label>
            <div className="sportsinput-form-control date-picker" onClick={() => toggleTimePicker('end')}>
              {formData.endTime ? formData.endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "종료 시간을 선택하세요"}
            </div>
            {uiState.isTimePickerOpen.end && (
              <div className="sportsinput-custom-modal" ref={endTimePickerRef}>
                <DatePicker
                  selected={formData.endTime}
                  onChange={(date) => {
                    handleInputChange('endTime', date);
                    setUiState({...uiState, isTimePickerOpen: {...uiState.isTimePickerOpen, end: false}});
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="시간"
                  dateFormat="hh:mm"
                  inline
                />
              </div>
            )}
          </div>
        </div>

        <div className="input-row">
          <div className="input-item">
            <label>강습<br/>최대인원</label>
            <select 
              className="sportsinput-form-control"
              value={formData.maxLessonParticipants}
              onChange={(e) => handleInputChange('maxLessonParticipants', Number(e.target.value))}
            >
              {[1,2,3,4,5,6,7,8,9,10].map((num) => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </div>
          <div className="input-item">
            <label>연습반<br/>최대인원</label>
            <select 
              className="sportsinput-form-control"
              value={formData.maxPracticeParticipants}
              onChange={(e) => handleInputChange('maxPracticeParticipants', Number(e.target.value))}
            >
              {[1,2,3,4,5,6,7,8,9,10].map((num) => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </div>
        </div>
        
        <div className="input-row">
          <div className="input-item">
            <label>수강신청 레벨</label>
            <select 
              className="sportsinput-form-control"
              value={formData.courseLevel}
              onChange={(e) => handleInputChange('courseLevel', Number(e.target.value))}
            >
              {[0,1,2,3,4,5,6,7,8,9].map((level) => (
                <option key={level} value={level}>{level}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="sportsinput-form-group-detailed">
          <label>상세 내용</label>
          <div>
            <div ref={quillRef} />
          </div>
        </div>

        <div className="sportsinput-div-input-save">
          {userLevel === "22" ? (
            <button className="sportsinput-btn btn-primary save-button" onClick={handleApprove}>승인</button>
          ) : (
            <button className="sportsinput-btn btn-primary save-button" onClick={handleSave}>저장</button>
          )}
        </div>
      </div>

      {uiState.showMessage && (
        <div className="message-box">
          <p>{uiState.messageContent}</p>
          <button className="messagebox-close-button" onClick={closeMessage}>
            닫기
          </button>
        </div>
      )}
    </div>
  );
};

export default SportsInput;