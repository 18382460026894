import React, { useState } from 'react'; // React와 상태 관리를 위한 useState를 불러옵니다.
import Slider from 'react-slick'; // 이미지 슬라이더 기능을 제공하는 외부 라이브러리입니다.
import Modal from 'react-modal'; // 모달(팝업 창)을 쉽게 구현할 수 있는 라이브러리입니다.
import { Link, useNavigate } from 'react-router-dom'; // 페이지 이동과 링크 처리를 위한 React Router 도구입니다.
import api from '../utils/api'; // (사용 중지 상태) 서버와 데이터를 주고받기 위한 API 함수가 담긴 파일입니다.
import Cookies from 'js-cookie'; // 브라우저 쿠키를 설정하거나 읽을 수 있는 라이브러리입니다.
import "slick-carousel/slick/slick.css"; // 이미지 슬라이더 기본 스타일을 적용하기 위한 CSS 파일입니다.
import "slick-carousel/slick/slick-theme.css"; // 이미지 슬라이더의 테마 스타일을 적용하기 위한 CSS 파일입니다.
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap의 기본 스타일입니다. 레이아웃과 컴포넌트 스타일링에 사용됩니다.
import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesome 아이콘 스타일입니다.
import "./common.css"; // 공통 스타일을 정의한 CSS 파일입니다.
import "./MainPage.css"; // 메인 페이지에서 사용되는 추가 스타일을 정의한 CSS 파일입니다.

import SportsLearning from './SportsLearning'; // SportsLearning이라는 컴포넌트를 불러옵니다. 다른 파일에 정의되어 있습니다.
import BottomEvent from '../components/BottomEvent'

// 이벤트 이미지와 관련된 정보를 배열로 정의합니다.
const eventImages = [
  {
    src: `${process.env.PUBLIC_URL}/images/slides/slide_sample1.png`, // 이벤트에 사용되는 이미지 경로입니다.
    title: '다양한 이벤트 1', // 이벤트의 제목입니다.
    description: '이벤트 응모하고 상품받자 1!', // 이벤트에 대한 간략한 설명입니다.
    buttonText: '바로가기', // 버튼에 표시될 텍스트입니다.,
    link: '/sports-learning'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/slides/slide_sample1.png`,
    title: '다양한 이벤트 2',
    description: '이벤트 응모하고 상품받자 2!',
    buttonText: '바로가기',
    link: '/sports-learning'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/slides/slide_sample1.png`,
    title: '다양한 이벤트 3',
    description: '이벤트 응모하고 상품받자 3!',
    buttonText: '바로가기',
    link: '/sports-learning'
  }
];

// 다양한 스포츠와 관련된 정보를 배열로 정의합니다.
const sports = [
  {
    src: `${process.env.PUBLIC_URL}/images/icon_sport/freediving.svg`, // 스포츠와 관련된 아이콘 이미지 경로입니다.
    title: '프리다이빙', // 스포츠의 이름입니다.
    description: '프리다이빙은 장비 없이 자유롭게 물속을 탐험하는 스포츠입니다. 호흡 훈련과 함께 깊은 바다에서의 특별한 경험을 제공합니다.' // 스포츠에 대한 설명입니다.
  },
  {
    src: `${process.env.PUBLIC_URL}/images/icon_sport/scuba-diving.svg`,
    title: '스쿠버다이빙',
    description: '스쿠버는 수중 호흡 장비를 활용하여 바다 속 세상을 탐험하는 스포츠입니다. 다양한 해양 생물과 아름다운 수중 경관을 만날 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/icon_sport/camping.svg`,
    title: '캠핑',
    description: '캠핑은 자연 속에서 휴식과 모험을 동시에 즐길 수 있는 아웃도어 활동입니다. 가족, 친구들과 함께 특별한 추억을 만들 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/icon_sport/mermaid.svg`,
    title: '머메이드',
    description: '머메이드는 인어의 움직임을 모방한 수중 스포츠입니다. 특별한 핀을 착용하고 우아한 수중 동작을 배우며 독특한 경험을 제공합니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/icon_sport/Pilates.svg`,
    title: '필라테스',
    description: '필라테스는 몸의 코어 근력을 강화하고 유연성을 향상시키는 운동입니다. 정확한 동작과 호흡을 통해 균형 잡힌 신체를 만들 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/icon_sport/ice-hockey.svg`,
    title: '아이스하키',
    description: '아이스하키는 빙판 위에서 펼쳐지는 역동적인 팀 스포츠입니다. 빠른 스케이팅과 정확한 퍽 컨트롤을 통해 짜릿한 경기를 즐길 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/icon_sport/snowboard.svg`,
    title: '스노우보드',
    description: '스노우보드는 눈 덮인 슬로프를 자유롭게 질주하는 겨울 스포츠입니다. 다양한 기술과 점프를 통해 스릴 넘치는 경험을 제공합니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/icon_sport/running.svg`,
    title: '러닝',
    description: '러닝은 가장 기본적이면서도 효과적인 유산소 운동입니다. 실외에서 자유롭게 달리며 체력을 향상시키고 스트레스를 해소할 수 있습니다.'
  }
];

// 물 관련 스포츠 목록입니다. 이 리스트는 특정 기능(필터링 등)에서 사용할 수 있습니다.
const WATER_SPORTS = ['프리다이빙', '스쿠버다이빙', '머메이드'];

// React-Modal을 사용하기 위한 설정으로, 모달의 root 요소를 설정합니다.
Modal.setAppElement('#root');

// 메인 페이지 컴포넌트입니다.
function MainPage({ isLoginModalOpen, closeLoginModal, userId, setUserId }) {
  // const [isModalOpen, setIsModalOpen] = useState(false); // 특정 스포츠를 클릭했을 때 상세 모달이 열리는지 여부를 관리합니다.
  // const [selectedSport, setSelectedSport] = useState(null); // 클릭한 스포츠 정보를 저장합니다.
  const [password, setPassword] = useState(''); // 로그인 비밀번호 입력 상태를 관리합니다.
  const navigate = useNavigate(); // 페이지 이동을 위한 함수입니다.

  // 슬라이더(이미지 슬라이드)의 동작을 설정하는 객체입니다.
  const settings = {
    dots: true, // 하단에 현재 슬라이드 위치를 표시하는 점을 표시합니다.
    infinite: true, // 슬라이드가 끝나면 다시 처음으로 돌아가도록 설정합니다.
    speed: 500, // 슬라이드 전환 속도(ms 단위)입니다.
    slidesToShow: 1, // 한 번에 한 슬라이드만 표시합니다.
    slidesToScroll: 1, // 한 번에 한 슬라이드씩 넘깁니다.
    autoplay: true, // 자동으로 슬라이드가 넘어가도록 설정합니다.
    autoplaySpeed: 5000 // 슬라이드가 자동으로 넘어가는 속도(ms 단위)입니다.
  };

  // 회원가입 버튼을 클릭했을 때 회원가입 페이지로 이동합니다.
  const handleSignupClick = (e) => {
    e.preventDefault(); // 기본 동작(페이지 새로고침)을 막습니다.
    closeLoginModal(); // 로그인 모달을 닫습니다.
    navigate('/signup'); // 회원가입 페이지로 이동합니다.
  };

  // 스포츠 버튼을 클릭했을 때 스포츠 학습 페이지로 이동합니다.
  // const handleApplyClick = () => {
  //   navigate('/sports-learning');
  // };
  const handleApplyClick = (sportTitle) => {
    navigate('/sports-location', { state: { selectedSport: sportTitle } }); // `selectedSport`로 전달
  };

  // 이벤트 이동 페이지
  const handleEventClick = (link) => {
    navigate(link); // link 매개변수에 따라 페이지 이동
  };
    
  return (
    <div className="main-container">
      {/* 이벤트 섹션: 슬라이더로 이벤트 이미지를 보여줍니다. */}
      <div className="event-section">
        <Slider {...settings}>
          {eventImages.map((image, index) => (
            <div key={index} className="slide">
              <div className="slide-block">
                <img src={image.src} alt={image.title} className="event-image" onClick={() => handleEventClick(image.link)}/>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* 스포츠 섹션: 다양한 스포츠를 그리드 형태로 보여줍니다. */}
      <div className="sports-section">
        <div className="sports-grid">
          {sports.map((sport, index) => (
            <div key={index} className="sport-item" onClick={() => handleApplyClick(sport.title)}>
              <img src={sport.src} alt={sport.title} />
              <span>{sport.title}</span>
            </div>
          ))}
        </div>
      </div>

      <BottomEvent />
      {/* 로그인 모달: 로그인 정보를 입력할 수 있는 창입니다. */}
      <Modal
        isOpen={isLoginModalOpen}
        onRequestClose={closeLoginModal}
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2>Login</h2>
            <button className="close-button" onClick={closeLoginModal}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              value={userId} // 사용자 ID 입력 상태를 연결합니다.
              onChange={(e) => setUserId(e.target.value)} // ID가 입력될 때마다 상태를 업데이트합니다.
              placeholder="Enter your ID"
              className="modal-input"
            />
            <input
              type="password"
              value={password} // 비밀번호 입력 상태를 연결합니다.
              onChange={(e) => setPassword(e.target.value)} // 비밀번호가 입력될 때마다 상태를 업데이트합니다.
              placeholder="Enter your Password"
              className="modal-input"
            />
            <Link to="/signup" onClick={handleSignupClick} className="signup-link">회원가입</Link>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MainPage; // MainPage 컴포넌트를 내보냅니다.
