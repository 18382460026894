// React와 라우터 기능을 위한 라이브러리 불러오기
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
// CSS 스타일시트 불러오기
import './BottomEvent.css';

const event = [
  {
    // title: "초보자를 위한 스포츠 가이드",
    // description: "처음 시작하는 분들을 위한 특별 프로그램",
    image: `${process.env.PUBLIC_URL}/images/bottom_event/bottom.svg`
  },
  {
    // title: "여름 시즌 스페셜",
    // description: "시원한 여름을 위한 특별 이벤트",
    image: `${process.env.PUBLIC_URL}/images/bottom_event/bottom.svg`
  },
  {
    // title: "그룹 클래스 할인",
    // description: "친구와 함께 시작하면 20% 할인",
    image: `${process.env.PUBLIC_URL}/images/bottom_event/bottom.svg`
  },
  {
    // title: "주말 원데이 클래스",
    // description: "부담없이 시작하는 주말 클래스",
    image: `${process.env.PUBLIC_URL}/images/bottom_event/bottom.svg`
  }
];

const settings = {
  dots: true, // 하단에 현재 슬라이드 위치를 표시하는 점을 표시합니다.
  infinite: true, // 슬라이드가 끝나면 다시 처음으로 돌아가도록 설정합니다.
  speed: 500, // 슬라이드 전환 속도(ms 단위)입니다.
  slidesToShow: 1, // 한 번에 한 슬라이드만 표시합니다.
  slidesToScroll: 1, // 한 번에 한 슬라이드씩 넘깁니다.
  autoplay: true, // 자동으로 슬라이드가 넘어가도록 설정합니다.
  autoplaySpeed: 7500 // 슬라이드가 자동으로 넘어가는 속도(ms 단위)입니다.
};

// BottomEvent 컴포넌트 정의
const BottomEvent = () => {
  const navigate = useNavigate(); // useNavigate 훅 사용

  // 이벤트 클릭 핸들러 함수 정의
  const handleEventClick = (path) => {
    navigate(path);
  };
  return(

<div className="Bottom-scroll-events-container">
  <div className="Bottom-scroll-events-wrapper">
    <Slider {...settings}>
      {event.map((event, index) => (
        <div key={index} className="Bottom-scroll-event-item" onClick={() => handleEventClick('/event-detail')}>
          <img src={event.image} className="Bottom-scroll-event-image" />
        </div>
      ))}
    </Slider>
  </div>
</div>
  );
}

export default BottomEvent;