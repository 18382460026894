import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';  // useLocation을 추가로 import합니다.
import './LocationPage.css';

// 장소 데이터 (카테고리 추가)
const locations = [
  {
    id: 1,
    name: "전체 장소 검색",
    address: "",
    image: `${process.env.PUBLIC_URL}/images/Location/IMG_8971.PNG`,
    operatingHours: "",
    isFavorite: false,
    category: "",
    poolDepth: "",
    pricing: "",
    facilities: [],
    mapLocation: null
  },
  {
    id: 2,
    name: "잠실 종합운동장",
    address: "서울 송파구 올림픽로 25 서울종합운동장",
    image: `${process.env.PUBLIC_URL}/images/Location/jamsil.jpeg`,
    operatingHours: "평일: 10:00 - 22:00 / 주말: 10:00 - 18:00",
    isFavorite: false,
    category: "잠수풀",
    poolDepth: "최대 5m",
    pricing: "1일 입장권 20,000원",
    facilities: ["샤워실", "락커룸", "무료 주차"],
    mapLocation: { lat: 37.51101, lng: 127.09450 }
  },
  {
    id: 3,
    name: "청주 학생수영장",
    address: "충청북도 청주시 청원구 공항로59번길 33",
    image: `${process.env.PUBLIC_URL}/images/Location/ju.jpeg`,
    operatingHours: "평일, 토요일 6:00~18:00 / 일요일 휴무",
    isFavorite: false,
    category: "잠수풀",
    poolDepth: "최대 3m",
    pricing: "입장권 15,000원",
    facilities: ["장비대여", "샤워실"],
    mapLocation: { lat: 36.64226, lng: 127.49969 }
  },
  {
    id: 4,
    name: "올림픽공원",
    address: "서울 송파구 올림픽로 424",
    image: `${process.env.PUBLIC_URL}/images/Location/allgong.jpeg`,
    operatingHours: "평일: 14:00 - 21:00 / 토: 12:00 - 17:00 / 일: 10:00 - 14:00",
    isFavorite: false,
    category: "잠수풀",
    poolDepth: "최대 4m",
    pricing: "종일권 25,000원",
    facilities: ["휴게실", "장비세척장"],
    mapLocation: { lat: 37.51966, lng: 127.11450 }
  },
  {
    id: 5,
    name: "성남 종합운동장",
    address: "경기 성남시 중원구 제일로 60",
    image: `${process.env.PUBLIC_URL}/images/Location/sungnam.jpeg`,
    operatingHours: "평일 09:00 - 21:00 / 주말 09:00 - 18:00",
    isFavorite: false,
    category: "잠수풀",
    poolDepth: "최대 3m",
    pricing: "1회 입장 15,000원",
    facilities: ["장비보관", "주차장"],
    mapLocation: { lat: 37.44035, lng: 127.15407 }
  },
  {
    id: 6,
    name: "수원 월드컵경기장",
    address: "경기 수원시 팔달구 월드컵로 310",
    image: `${process.env.PUBLIC_URL}/images/Location/suwon.jpeg`,
    operatingHours: "평일 09:00 - 22:00 / 주말 09:00 - 22:00",
    isFavorite: false,
    category: "잠수풀",
    poolDepth: "최대 3m",
    pricing: "평일 18,000원 / 주말 22,000원",
    facilities: ["샤워실", "주차장"],
    mapLocation: { lat: 37.28665, lng: 127.02009 }
  },
  {
    id: 7,
    name: "일산 수작코리아",
    address: "경기도 고양시 덕양구 동헌로235번길 120-57 (대자동)",
    image: `${process.env.PUBLIC_URL}/images/Location/ilsan.jpeg`,
    operatingHours: "평일 10:00 - 17:00 / 화·수 18:30 - 22:00",
    isFavorite: false,
    category: "잠수풀",
    poolDepth: "최대 4m",
    pricing: "1일권 25,000원",
    facilities: ["샤워실", "장비세척장"],
    mapLocation: { lat: 37.70786, lng: 126.82905 }
  },
  {
    id: 8,
    name: "대전 용운국제수영장",
    address: "대전 동구 동부로 138 용운국제수영장",
    image: `${process.env.PUBLIC_URL}/images/Location/alps.jpeg`,
    operatingHours: "평일 10:00 - 21:00 / 주말 09:00 - 17:00",
    isFavorite: false,
    category: "잠수풀",
    poolDepth: "최대 3.5m",
    pricing: "종일권 20,000원",
    facilities: ["샤워실", "락커룸"],
    mapLocation: { lat: 36.32592, lng: 127.45978 }
  },
  {
    id: 9,
    name: "송도 스포츠파크",
    address: "인천 연수구 인천신항대로892번길 40",
    image: `${process.env.PUBLIC_URL}/images/Location/songdo.jpeg`,
    operatingHours: "평일 09:00 - 21:00 / 주말 09:00 - 16:00",
    isFavorite: false,
    category: "잠수풀",
    poolDepth: "최대 3m",
    pricing: "1회 15,000원",
    facilities: ["락커룸"],
    mapLocation: { lat: 37.39635, lng: 126.62839 }
  },
  {
    id: 10,
    name: "용인 아르피아",
    address: "경기 용인시 수지구 포은대로 499",
    image: `${process.env.PUBLIC_URL}/images/Location/yong.jpeg`,
    operatingHours: "평일 08:00 - 22:00, 토 08:00 - 18:00, 일 08:00 - 17:00",
    isFavorite: false,
    category: "잠수풀",
    poolDepth: "최대 3m",
    pricing: "입장권 18,000원",
    facilities: ["샤워실"],
    mapLocation: { lat: 37.32418, lng: 127.09457 }
  },
  {
    id: 11,
    name: "광명 뉴서울 다이빙풀",
    address: "경기 광명시 하안로288번길 15",
    image: `${process.env.PUBLIC_URL}/images/Location/gwangmyeong.jpeg`,
    operatingHours: "평일 08:00 - 18:00",
    isFavorite: false,
    category: "잠수풀",
    poolDepth: "최대 5m",
    pricing: "종일권 23,000원",
    facilities: ["샤워실", "주차장", "장비보관"],
    mapLocation: { lat: 37.45594, lng: 126.85747 }
  },
  {
    id: 12,
    name: "시흥 파라다이브",
    address: "경기 시흥시 거북섬중앙로 1 1동3층 303호",
    image: `${process.env.PUBLIC_URL}/images/Location/para.jpeg`,
    operatingHours: "주말/평일 08:00 - 23:00",
    isFavorite: false,
    category: "개방수역",
    poolDepth: "최대 10m",
    pricing: "1일권 30,000원",
    facilities: ["샤워실", "휴게실"],
    mapLocation: { lat: 37.35544, lng: 126.73369 }
  },
  {
    id: 13,
    name: "오산 테마잠수풀",
    address: "경기 오산시 수청동 607-1",
    image: `${process.env.PUBLIC_URL}/images/Location/osan.jpeg`,
    operatingHours: "주말/평일 09:00 - 23:00",
    isFavorite: false,
    category: "개방수역",
    poolDepth: "최대 8m",
    pricing: "입장권 28,000원",
    facilities: ["샤워실", "장비보관", "장비대여"],
    mapLocation: { lat: 37.14941, lng: 127.07769 }
  },
  {
    id: 14,
    name: "가평 K26",
    address: "경기 가평군 청평면 고재길 262-57",
    image: `${process.env.PUBLIC_URL}/images/Location/k26.jpeg`,
    operatingHours: "평일 09:00 - 22:00 / 토 07:00 - 21:00 / 일 휴무",
    isFavorite: false,
    category: "개방수역",
    poolDepth: "최대 26m",
    pricing: "평일 40,000원, 주말 50,000원",
    facilities: ["샤워실", "휴게실", "장비보관"],
    mapLocation: { lat: 37.74051, lng: 127.42227 }
  },
  {
    id: 15,
    name: "용인 딥스테이션",
    address: "경기 용인시 처인구 포곡읍 성산로 523",
    image: `${process.env.PUBLIC_URL}/images/Location/deeps.jpg`,
    operatingHours: "08:00 - 23:00",
    isFavorite: false,
    category: "개방수역",
    poolDepth: "최대 36m",
    pricing: "평일 48,000원, 주말 66,000원",
    facilities: ["샤워실", "휴게실", "장비보관", "슈트대여"],
    mapLocation: { lat: 37.28560, lng: 127.22200 }
  }
];

// ─────────────────────────────────────────────────────────
// 2) 카테고리 리스트
// ─────────────────────────────────────────────────────────
const categories = ['전체', '잠수풀', '개방수역'];

function LocationPage() {
  const navigate = useNavigate();
  // 라우터 state에서 선택된 Sport (optional)
  const locState = useLocation();
  const { selectedSport } = locState.state || {};

  // 카테고리 / 복사된 ID / 모달 제어
  const [selectedCategory, setSelectedCategory] = useState('전체');
  const [copiedId, setCopiedId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // 뒤로가기
  const handleBack = () => {
    navigate(-1);
  };

  // 카테고리 필터링
  const filteredLocations = selectedCategory === '전체'
    ? locations
    : locations.filter(loc => loc.category === selectedCategory);

  // 주소 복사
  const handleCopyAddress = async (e, address, id) => {
    e.stopPropagation();
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(address);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = address;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }
      setCopiedId(id);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error('복사 실패:', err);
      prompt('복사가 지원되지 않는 환경입니다. 직접 복사해주세요:', address);
    }
  };

  // 모달 열기
  const openModal = (loc) => {
    setSelectedLocation(loc);
    setShowModal(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setShowModal(false);
    setSelectedLocation(null);
  };

  // 모달 내 "이 장소 예약하기" → sports-learning 페이지로 이동
  const handleLocationSelect = (loc) => {
    navigate('/sports-learning', { 
      state: { 
        location: loc.name === "전체 장소 검색" ? "" : loc.name,
        selectedSport
      } 
    });
  };

  // 지도 렌더
  const renderMap = (loc) => {
    if (!loc || !loc.mapLocation) return null;
    const { lat, lng } = loc.mapLocation;
    const mapUrl = `https://maps.google.com/maps?q=${lat},${lng}&z=15&output=embed`;
    return (
      <iframe
        title="location-map"
        src={mapUrl}
        width="100%"
        height="250"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    );
  };

  return (
    <div className="location-page">
      {/* 모바일 뒤로가기 버튼 (데스크톱은 필요하면 추가) */}
      <button className="back-button mobile" onClick={handleBack} />

      <h1 className="page-title">장소 선택</h1>

      {/* 카테고리 버튼들 */}
      <div className="category-buttons">
        {categories.map(category => (
          <button
            key={category}
            className={`category-button ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* 장소 목록 */}
      <div className="locations-grid">
        {filteredLocations.map(loc => {
          // "전체"일 경우 간략 처리 or 별도 디자인 (선택사항)
          if (loc.name === "전체 장소 검색") {
            return (
              <div 
                key={loc.id}
                className="location-card whole-location-card"
                onClick={() => handleLocationSelect(loc)}
              >
                <div className="location-image">
                  <img src={loc.image} alt="전체 장소 검색" />
                </div>
                <div className="location-info">
                  <h3>전체 장소 검색</h3>
                </div>
              </div>
            );
          }

          // 일반 장소
          return (
            <div key={loc.id} className="location-card" onClick={() => handleLocationSelect(loc)}>
              <div className="location-image">
                <img src={loc.image} alt={loc.name} />
                {loc.isFavorite && <span className="favorite-badge">★</span>}
              </div>
              <div className="location-info">
                <h3>{loc.name}</h3>

                <div className="address-container">
                  <span className="address">{loc.address}</span>
                  <button 
                    className="copy-button"
                    onClick={(e) => handleCopyAddress(e, loc.address, loc.id)}
                  >
                    {copiedId === loc.id ? 'Copied!' : '복사'}
                  </button>
                </div>
                {/* 자세히 보기 버튼 → 모달 열기 */}
                <button
                  className="detail-button"
                  onClick={(e) => {
                    e.stopPropagation(); // 부모 div 클릭 이벤트로 올라가는 것 방지
                    openModal(loc);
                  }}
                >
                  자세히 보기
                </button>
              </div>
            </div>
          );
        })}
      </div>

      {/* 모달 (showModal === true && selectedLocation 존재 시) */}
      {showModal && selectedLocation && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {/* 닫기 버튼 */}
            <button className="modal-close" onClick={closeModal}>×</button>

            <h2 className="modal-title">{selectedLocation.name}</h2>
            
            <div className="modal-map">
              {renderMap(selectedLocation)}
            </div>

            <div className="modal-details">
              <div className="detail-item">
                <h3>주소</h3>
                <p>{selectedLocation.address || '정보 없음'}</p>
              </div>

              <div className="detail-item">
                <h3>운영 시간</h3>
                <p>{selectedLocation.operatingHours || '정보 없음'}</p>
              </div>

              <div className="detail-item">
                <h3>다이빙 풀 깊이</h3>
                <p>{selectedLocation.poolDepth || '정보 없음'}</p>
              </div>

              <div className="detail-item">
                <h3>이용 요금</h3>
                <p>{selectedLocation.pricing || '정보 없음'}</p>
              </div>

              <div className="detail-item">
                <h3>시설 정보</h3>
                <div className="facilities-list">
                  {selectedLocation.facilities && selectedLocation.facilities.length > 0 ? (
                    selectedLocation.facilities.map((facility, idx) => (
                      <span key={idx} className="facility-tag">{facility}</span>
                    ))
                  ) : (
                    <p style={{ margin: 0 }}>정보 없음</p>
                  )}
                </div>
              </div>
            </div>
            <button
              className="modal-action-button"
              onClick={() => {
                closeModal();
                handleLocationSelect(selectedLocation);
              }}
            >
              이 장소 예약하기
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LocationPage;