// import React from "react";
// import "./UnderConstruction.css"; // 기존 CSS 파일 사용

// const UnderConstructionModal = ({ isOpen, onClose }) => {
//   if (!isOpen) return null; // 모달이 열리지 않으면 렌더링하지 않음

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         {/* X 버튼 추가 */}
//         <button className="close-icon" onClick={onClose}>✖</button>

//         <img src="/images/logo.png" alt="BUCCL Logo" className="logo" />

//         <h2>딥스프리다이빙 카페 바로가기</h2> 
//         <img src="/images/deeps.jpeg" alt="DEEPS Logo" className="logo-deeps" />

//         <h2>페이지 준비 중입니다</h2>
//         <p>더 나은 서비스로 찾아뵙기 위해 준비 중입니다.<br />빠른 시일 내에 완성된 모습으로 돌아오겠습니다.</p>
//         <div className="info">
//           <p>
//             <span className="icon">&#128197;</span>  {/* 📅 (캘린더 아이콘) */}
//             <strong> 예상 완료일:</strong> 2025년 6월
//           </p>
//           <p>
//             <span className="icon">&#9993;</span>  {/* ✉ (이메일 아이콘) */}
//             <strong> 문의사항:</strong> <a href="mailto:buccl@buccl.com">buccl@buccl.com</a>
//           </p>
//         </div>

//         <footer>© 2025 주식회사 버킷리스트클래스. All rights reserved.</footer>
//       </div>
//     </div>
//   );
// };

// export default UnderConstructionModal;

import React from "react";
import "./UnderConstruction.css"; // 기존 CSS 파일 사용

const UnderConstructionModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-icon" onClick={onClose}>✖</button>

        <img src="/images/logo.png" alt="BUCCL Logo" className="logo" />

        <h2>페이지 준비 중입니다</h2>
        <p>
          더 나은 서비스로 찾아뵙기 위해 준비 중입니다.<br />
          빠른 시일 내에 완성된 모습으로 돌아오겠습니다.
        </p>

        <div className="deeps-link-container small">
          <a
            href="https://cafe.naver.com/freefenbeat"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/deeps.jpeg"
              alt="DEEPS Logo"
              className="logo-deeps"
            />
          </a>
          <p className="click-hint">👆 이미지를 클릭해 카페로 이동하세요!</p>
          <h3 className="deeps-title-small">🌊 딥스프리다이빙이 버킷리스트클래스와 함께합니다!</h3>
        </div>

        <div className="info">
          <p>
            <span className="icon">&#128197;</span>
            <strong> 예상 완료일:</strong> 2025년 6월
          </p>
          <p>
            <span className="icon">&#9993;</span>
            <strong> 문의사항:</strong>{" "}
            <a href="mailto:buccl@buccl.com">buccl@buccl.com</a>
          </p>
        </div>
        <footer>© 2025 주식회사 버킷리스트클래스. All rights reserved.</footer>
      </div>
    </div>
  );
};

export default UnderConstructionModal;
