// React와 상태 관리를 위한 useState 훅을 불러옵니다.
import React, { useState } from 'react';
// 모달 창 구현을 위한 react-modal 라이브러리를 불러옵니다.
import Modal from 'react-modal';
// 페이지 내 라우팅을 위한 useNavigate 훅을 불러옵니다.
import { useNavigate } from 'react-router-dom';
// 공통 스타일 CSS 파일을 불러옵니다.
import './common.css';

/**
 * 장비 구매 페이지 컴포넌트
 * 프리다이빙 장비 목록을 보여주고 구매할 수 있는 페이지입니다.
 */
const EquipmentPurchase = () => {
    // 현재 선택된 장비 아이템을 저장하는 상태
    const [selectedItem, setSelectedItem] = useState(null);
    // 모달 창의 열림/닫힘 상태를 관리하는 상태
    const [isModalOpen, setIsModalOpen] = useState(false);

    // 장비 목록 데이터
  const items = [
    {
      // id: 1,
      // image: '/images/글라이드.jpg',
      // title: '트루다이브 고탄성 수트',
      // seller: '딥스프리다이빙',
      // date: '2024.02.29',
      // description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
      // 각 장비의 고유 ID
      id: 1,
      // 장비 이미지 경로
      image: '/images/글라이드.jpg',
      // 장비 제목
      title: '트루다이브 고탄성 수트',
      // 판매자 정보
      seller: '딥스프리다이빙',
      // 등록 날짜
      date: '2024.02.29',
      // 상세 설명 (텍스트 서식 포함)
      description: `◆ 브랜드 : 트루다이브 (TRUDIVE)

      ◆ 용도 : 슈트

      ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
      후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
      제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.

      ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드

      ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨

      ◆ 가격 : 498,000원 -> 425,000원

      * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.

      ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
      (또는 담당강사님께 상담 가능)

      ◆ 수령방법 : 배송
      주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
      재고가 없으면 4주정도 소요됩니다.`,
    },
    {
        id: 2,
        image: '/images/글라이드.jpg',
        title: '트루다이브 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },{
        id: 3,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },
      {
        id: 4,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },
      {
        id: 5,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },
      {
        id: 6,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },
      {
        id: 7,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },
      {
        id: 8,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      }
    // 다른 아이템들을 추가
  ];
  
    /**
     * 카드 클릭 이벤트 핸들러
     * @param {Object} item - 클릭된 장비 아이템 정보
     */
    const handleCardClick = (item) => {
      setSelectedItem(item); // 선택된 아이템 설정
      setIsModalOpen(true); // 모달 창 열기
  };

  /**
   * 모달 창 닫기 함수
   * 모달을 닫고 선택된 아이템 정보를 초기화합니다.
   */
  const closeModal = () => {
      setIsModalOpen(false);
      setSelectedItem(null);
  };

  // 페이지 이동을 위한 네비게이션 훅
  const navigate = useNavigate();

  /**
   * 작성 아이콘 클릭 이벤트 핸들러
   * 장비 등록 페이지로 이동합니다.
   */
  const handleIconClick = () => {
      navigate('/equipment_purchase_input');
  };

  return (
      // 전체 페이지 컨테이너
      <div className="main-container">
          {/* 페이지 상단 제목 영역 */}
          <div className="title-container">
              <h2 className="section-title">장비 구매</h2>
          </div>
          {/* 장비 등록 아이콘 영역 */}
          <div className="icon-container">
              <i className="fas fa-edit title-icon" onClick={handleIconClick}></i>
          </div>

          {/* 장비 카드 그리드 영역 */}
          <div className="grid-container">
              {/* items 배열을 순회하면서 각 장비 카드를 생성 */}
              {items.map((item) => (
                  <div className="card" key={item.id} onClick={() => handleCardClick(item)}>
                      {/* 장비 이미지 */}
                      <img src={item.image} alt={item.title} className="card-image" />
                      {/* 장비 정보 영역 */}
                      <div className="card-content">
                          <h3 className="card-title">{item.title}</h3>
                          <p className="card-seller">{item.seller}</p>
                          <p className="card-date">{item.date}</p>
                      </div>
                  </div>
              ))}
          </div>

          {/* 장비 상세 정보 모달 */}
          <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              className="ReactModal__Content"
              overlayClassName="ReactModal__Overlay"
          >
              {/* 선택된 아이템이 있을 경우에만 모달 내용을 표시 */}
              {selectedItem && (
                  <div className="modal-content">
                      {/* 모달 상단 영역 (닫기 버튼) */}
                      <div className="modal-header">
                          <button className="close-button" onClick={closeModal}>
                              <i className="fas fa-times"></i>
                          </button>
                      </div>
                      {/* 장비 이미지 */}
                      <img src={selectedItem.image} alt={selectedItem.title} className="modal-image" />
                      {/* 장비 상세 설명 영역 */}
                      <div className="modal-description">
                          <h2>{selectedItem.title}</h2>
                          <p>{selectedItem.date}</p>
                          <p>{selectedItem.seller}</p>
                          <p className="modal-details">{selectedItem.description}</p>
                      </div>
                      {/* 모달 하단 버튼 영역 */}
                      <div className="modal-footer">
                          {/* 구매하기 버튼 */}
                          <button onClick={closeModal} className="modal-button">
                              <i className="fa fa-plus-circle"></i> 구매하기
                          </button>
                          {/* 문의하기 버튼 */}
                          <button onClick={closeModal} className="modal-button">
                              <i className="fa fa-comments"></i> 문의
                          </button>
                      </div>
                  </div>
              )}
          </Modal>
      </div>
  );
};

// 컴포넌트를 내보내기
export default EquipmentPurchase;